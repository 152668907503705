import "./sidebar.css";
import {
   LineStyle,
   Timeline,
   TrendingUp,
   PermIdentity,
   Storefront,
   AttachMoney,
   BarChart,
   MailOutline,
   DynamicFeed,
   ChatBubbleOutline,
   WorkOutline,
   Report,
   Dashboard,
   FormatListNumbered,
   PersonAdd,
   LocalHospital,
   FeaturedPlayListRounded,
   CategoryRounded,
   PaymentRounded,
   ExitToAppRounded,
   SupervisorAccount,
   Policy,
   EmojiSymbols,
} from "@material-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react'

export default function Sidebar() {

   const auth = localStorage.getItem("user");

   const [category, setCategory] = useState("");
   const [side_tab, setSide_tab] = useState("");

   const navigat = useNavigate()

   const logout = async (e) => {
      console.log("logout")
      localStorage.removeItem('user');
      // localStorage.clear();
      window.location.href = '/'
   }

   useEffect(() => {
      console.log("auth", auth)
      if (auth) {
         const parsed = JSON.parse(auth);
         const category = parsed[0].category;
         const side_tab_1 = parsed[0].side_tab;

         setCategory(category)

         if (side_tab_1) {

            const side_tab_2 = JSON.parse(side_tab_1);
            const side_tab = side_tab_2.join(","); //"red,blue,green"

            console.log("category", category)
            console.log("side_tab", side_tab)

            setSide_tab(side_tab_1)
         }
      }

   }, [])


   return (
      <div className="sidebar">
         <div className="sidebarWrapper">

            <ul className="sidebarList">
               <Link className="link">
                  <li className="logotop">Deoconsult</li>
               </Link>
            </ul>


            <>
               {category == "Main" ?
                  <div className="sidebarMenu">
                     <h3 className="sidebarTitle">Dashboard</h3>

                     <ul className="sidebarList">
                        <Link to="/" className="link">
                           <li className="sidebarListItem active"><Dashboard className="sidebarIcon" />Dashboard</li>
                        </Link>

                        <Link to="/Add_Admin" className="link">
                           <li className="sidebarListItem active"><SupervisorAccount className="sidebarIcon" />Add Admin</li>
                        </Link>

                        <Link to="/Admin_List" className="link">
                           <li className="sidebarListItem active"><FormatListNumbered className="sidebarIcon" />Admin List</li>
                        </Link>
                     </ul>
                  </div>
                  :
                  <div className="sidebarMenu">
                     <h3 className="sidebarTitle">Dashboard</h3>

                     <ul className="sidebarList">
                        <Link to="/" className="link">
                           <li className="sidebarListItem active"><Dashboard className="sidebarIcon" />Dashboard</li>
                        </Link>
                     </ul>
                  </div>
               }



               {category == "Main" || category == "Administrator" ?
                  <>
                     <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Patient</h3>

                        <ul className="sidebarList">
                           <Link to="/Patient_list" className="link">
                              <li className="sidebarListItem active"><FormatListNumbered className="sidebarIcon" />Patient List</li>
                           </Link>

                           <Link to="/add_patient" className="link">
                              <li className="sidebarListItem"><PersonAdd className="sidebarIcon" />Add Patient</li>
                           </Link>
                        </ul>
                     </div>


                     <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Provider</h3>

                        <ul className="sidebarList">
                           <Link to="/Doctor_list" className="link">
                              <li className="sidebarListItem"><FormatListNumbered className="sidebarIcon" />Provider List</li>
                           </Link>

                           <Link to="/add_doctor" className="link">
                              <li className="sidebarListItem"><PersonAdd className="sidebarIcon" />Add Provider</li>
                           </Link>

                           <Link to="/Pending_doctor" className="link">
                              <li className="sidebarListItem"><LocalHospital className="sidebarIcon" />Pending Provider</li>
                           </Link>
                        </ul>
                     </div>


                     <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Add Category</h3>

                        <ul className="sidebarList">
                           <Link to="/Primary_categories" className="link">
                              <li className="sidebarListItem"><CategoryRounded className="sidebarIcon" />Primary Categories</li>
                           </Link>

                           <Link to="/Secondary_categories" className="link">
                              <li className="sidebarListItem"><CategoryRounded className="sidebarIcon" />Secondary Categories</li>
                           </Link>
                        </ul>
                     </div>

                     <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Review</h3>

                        <ul className="sidebarList">
                           <Link to="/Review_Manage" className="link">
                              <li className="sidebarListItem"><CategoryRounded className="sidebarIcon" />Review Manage</li>
                           </Link>
                        </ul>
                     </div>

                     <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Appointment</h3>

                        <ul className="sidebarList">
                           <Link to="/Appointment" className="link">
                              <li className="sidebarListItem active"><FeaturedPlayListRounded className="sidebarIcon" />Appointment</li>
                           </Link>
                        </ul>
                     </div>
                  </>
                  :
                  null
               }




               {category == "Main" || category == "Accountant" ?
                  <div className="sidebarMenu">
                     <h3 className="sidebarTitle">Wallet</h3>

                     <ul className="sidebarList">
                        <Link to="/Pending_payout" className="link">
                           <li className="sidebarListItem"><PaymentRounded className="sidebarIcon" />Pending Payout</li>
                        </Link>

                        <Link to="/refund" className="link">
                           <li className="sidebarListItem"><Storefront className="sidebarIcon" />Refund</li>
                        </Link>

                        <Link to="/Transactions" className="link">
                           <li className="sidebarListItem"><Storefront className="sidebarIcon" />Transactions</li>
                        </Link>

                        <Link to="/Percentage" className="link">
                           <li className="sidebarListItem"><EmojiSymbols className="sidebarIcon" />Percentage</li>
                        </Link>
                     </ul>
                  </div>
                  :
                  null
               }


               <div className="sidebarMenu">
                  <h3 className="sidebarTitle">Privacy Policy</h3>

                  <ul className="sidebarList">
                     <Link to="/Privacy_Policy" className="link">
                        <li className="sidebarListItem active"><Policy className="sidebarIcon" />Privacy Policy</li>
                     </Link>
                  </ul>
               </div>


               <div className="sidebarMenu">
                  <h3 className="sidebarTitle">Log Out</h3>

                  <ul className="sidebarList">
                     <Link onClick={logout} className="link">
                        <li className="sidebarListItem active"><ExitToAppRounded className="sidebarIcon" />logout</li>
                     </Link>
                  </ul>
               </div>
            </>

         </div>
      </div>




      //  <div className="sidebar">
      //    <div className="sidebarWrapper">

      //       <ul className="sidebarList">
      //          <Link className="link">
      //             <li className="logotop">Deoconsult</li>
      //          </Link>
      //       </ul>


      //      <>
      //      <div className="sidebarMenu">
      //        <h3 className="sidebarTitle">Dashboard</h3>

      //        <ul className="sidebarList">
      //            {side_tab == "Dashboard" || category == "Main" ?
      //            <Link to="/" className="link">
      //                <li className="sidebarListItem active"><Dashboard className="sidebarIcon" />Dashboard</li>
      //            </Link>
      //            :
      //            null
      //            }


      //            {side_tab == "Add Admin" || category == "Main" ?
      //            <Link to="/Add_Admin" className="link">
      //                <li className="sidebarListItem active"><SupervisorAccount className="sidebarIcon" />Add Admin</li>
      //            </Link>
      //            :
      //            null
      //            }

      //            {side_tab == "Admin List" || category == "Main" ?
      //            <Link to="/Admin_List" className="link">
      //                <li className="sidebarListItem active"><FormatListNumbered className="sidebarIcon" />Admin List</li>
      //            </Link>
      //            :
      //            null
      //            }
      //        </ul>
      //      </div>

      //     <div className="sidebarMenu">
      //        <h3 className="sidebarTitle">Patient</h3>

      //        <ul className="sidebarList">
      //            {side_tab == "Patient List" || category == "Main" ?
      //            <Link to="/Patient_list" className="link">
      //                <li className="sidebarListItem active"><FormatListNumbered className="sidebarIcon" />Patient List</li>
      //            </Link>
      //            :
      //            null
      //            }

      //            {side_tab == "Add Patient" || category == "Main" ?
      //            <Link to="/add_patient" className="link">
      //                <li className="sidebarListItem"><PersonAdd className="sidebarIcon" />Add Patient</li>
      //            </Link>
      //            :
      //            null
      //            }
      //       </ul>
      //     </div>


      //     <div className="sidebarMenu">
      //        <h3 className="sidebarTitle">Provider</h3>

      //        <ul className="sidebarList">
      //          {side_tab == "Provider List" || category == "Main" ?
      //          <Link to="/Doctor_list" className="link">
      //             <li className="sidebarListItem"><FormatListNumbered className="sidebarIcon" />Provider List</li>
      //          </Link>
      //          :
      //          null
      //          }

      //         {side_tab == "Add Provider" || category == "Main" ?
      //          <Link to="/add_doctor" className="link">
      //             <li className="sidebarListItem"><PersonAdd className="sidebarIcon" />Add Provider</li>
      //          </Link>
      //          :
      //          null
      //          }

      //          {side_tab == "Pending Provider" || category == "Main" ?
      //          <Link to="/Pending_doctor" className="link">
      //             <li className="sidebarListItem"><LocalHospital className="sidebarIcon" />Pending Provider</li>
      //          </Link>
      //          :
      //          null
      //          }

      //        </ul>
      //     </div>


      //     <div className="sidebarMenu">
      //        <h3 className="sidebarTitle">Add Category</h3>

      //        <ul className="sidebarList">
      //          {side_tab == "Primary Categories" || category == "Main" ?
      //          <Link to="/Primary_categories" className="link">
      //             <li className="sidebarListItem"><CategoryRounded className="sidebarIcon" />Primary Categories</li>
      //          </Link>
      //          :
      //          null
      //          }

      //          {side_tab == "Secondary Categories" || category == "Main" ?
      //          <Link to="/Secondary_categories" className="link">
      //             <li className="sidebarListItem"><CategoryRounded className="sidebarIcon" />Secondary Categories</li>
      //          </Link>
      //          :
      //          null
      //          }
      //        </ul>
      //     </div>


      //     {side_tab == "Review Manage" || category == "Main" ?
      //     <div className="sidebarMenu">
      //        <h3 className="sidebarTitle">Review</h3>

      //        <ul className="sidebarList">
      //          <Link to="/Review_Manage" className="link">
      //             <li className="sidebarListItem"><CategoryRounded className="sidebarIcon" />Review Manage</li>
      //          </Link>
      //        </ul>
      //     </div>
      //     :
      //     null
      //     }


      //     {side_tab == "Appointment" || category == "Main" ?
      //     <div className="sidebarMenu">
      //        <h3 className="sidebarTitle">Appointment</h3>

      //        <ul className="sidebarList">
      //            <Link to="/Appointment" className="link">
      //                <li className="sidebarListItem active"><FeaturedPlayListRounded className="sidebarIcon" />Appointment</li>
      //            </Link>
      //        </ul>
      //     </div>
      //     :
      //     null
      //     }

      //     <div className="sidebarMenu">
      //        <h3 className="sidebarTitle">Wallet</h3>

      //        <ul className="sidebarList">
      //          {side_tab == "Pending Payout" || category == "Main" ?
      //          <Link to="/Pending_payout" className="link">
      //             <li className="sidebarListItem"><PaymentRounded className="sidebarIcon" />Pending Payout</li>
      //          </Link>
      //          :
      //          null
      //          }

      //          {side_tab == "Refund" || category == "Main" ?
      //          <Link to="/refund" className="link">
      //             <li className="sidebarListItem"><Storefront className="sidebarIcon" />Refund</li>
      //          </Link>
      //          :
      //          null
      //          }

      //          {side_tab == "Transactions" || category == "Main" ?
      //          <Link to="/Transactions" className="link">
      //             <li className="sidebarListItem"><AttachMoney className="sidebarIcon" />Transactions</li>
      //          </Link>
      //          :
      //          null
      //          }

      //          {side_tab == "Percentage" || category == "Main" ?
      //          <Link to="/Percentage" className="link">
      //             <li className="sidebarListItem"><AttachMoney className="sidebarIcon" />Percentage</li>
      //          </Link>
      //          :
      //          null
      //          }
      //        </ul>
      //     </div>


      //     <div className="sidebarMenu">
      //        <h3 className="sidebarTitle">Log Out</h3>

      //        <ul className="sidebarList">
      //            <Link onClick={logout} className="link">
      //                <li className="sidebarListItem active"><ExitToAppRounded className="sidebarIcon" />logout</li>
      //            </Link>
      //        </ul>
      //     </div>
      //     </>

      //    </div>
      //  </div>
   );
}
