import React, { useState, useEffect } from 'react'
import './Primary_categories.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Link, parsePath, useParams } from "react-router-dom"
import Primary_categories_Paginations from './Primary_categories_Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";

export default function Primary_categories() {

   const [refunds, setRefunds] = useState([]);

   const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage, setPostsPerPage] = useState(5);

   const lastPostIndex = currentPage * postsPerPage
   const firstPostIndex = lastPostIndex - postsPerPage
   const currentPosts = refunds.slice(firstPostIndex, lastPostIndex)

   const [modalDelete, setModalDelete] = useState(false);
   const [add_primary, setAdd_primary] = useState(false);
   const [edit_primary, setEdit_primary] = useState(false);

   const [id, setId] = React.useState("");
   const [name, setName] = React.useState("");
   const [file, setFile] = useState(null);
   const [old_file, setOld_file] = useState(null);

   const [patLoading, setPatLoading] = useState(false);

   const params = useParams();

   useEffect(() => {
      get_primary_category()
   }, [])

   const get_primary_category = async () => {
      let result = await fetch(Connection + "primary_categories", {

      })
      result = await result.json()
      setRefunds(result)
      console.warn(result)
   }


   const fileSelectedHandler = (e) => {
      setFile(e.target.files[0]);
      console.log("sdkslkdlksddvfvf", e.target.files[0]);
   }


   const connect = async (event) => {

      event.preventDefault();
      console.log("connect_file1", file)
      console.log("name", name)
      setPatLoading(true);

      const form_data = new FormData();

      form_data.append("name", name);
      form_data.append("profg", file);

      const api = Connection + "upload_img"
      const result = await fetch(api, {
         method: "POST",
         body: form_data,
      })
         .then((result) => result.json())
         .then((result) => {
            console.log("result", result);
            setPatLoading(false);
            setAdd_primary(false)
            get_primary_category()
         })

         .catch((error) => {
            console.error(error);
            setPatLoading(false);
            alert("Something went wrong")
         });

      console.log("result123", result)
   }


   const open_delete_modal = (id) => {
      setModalDelete(true)

      setId(id)
   }


   const delete_primarycategory = async (event) => {
      event.preventDefault();

      setPatLoading(true);

      let result = await fetch(Connection + `delete_primarycate/${id}`)
      result = await result.json()
      console.log("result123", result)

      if (result) {
         setPatLoading(false);
         setModalDelete(false)
         get_primary_category()
      }
   }


   const open_editprime_modal = (id, name, logo) => {
      setEdit_primary(true)

      setId(id)
      setName(name)
      setFile(logo)
      setOld_file(logo)
   }


   const update_primarycategoty = async (event) => {
      if (old_file == file) {
         alert("Please make complete changes before updating.");
      }
      else {
         event.preventDefault();
         console.log("connect_file1", file)
         console.log("name", name)
         setPatLoading(true);

         const form_data = new FormData();

         form_data.append("id", id);
         form_data.append("name", name);
         form_data.append("edit_profg", file);

         const api = Connection + "edit_primary_category"
         const result = await fetch(api, {
            method: "POST",
            body: form_data,
         })
            .then((result) => result.json())
            .then((result) => {
               console.log("result", result);
               setPatLoading(false);
               setEdit_primary(false)
               get_primary_category()
            })

            .catch((error) => {
               console.error(error);
               setPatLoading(false);
               alert("Something went wrong")
            });

         console.log("result123", result)
      }
   }


   return (
      <div className='primary_category_main'>

         <div className='respons_primary_card'>
            <div className='head_card'>
               <h2>Primary Categories</h2>
               <button onClick={() => setAdd_primary(true)}>Add Category</button>
            </div>
            <hr></hr>

            <div className='primary_table'>
               <table className='primary_tble_tag'>
                  <tr>
                     <th>#</th>
                     <th>Category Image</th>
                     <th>Category Name</th>
                     <th>Status</th>
                     <th>Action</th>
                  </tr>


                  {currentPosts.map((row) => (
                     <tr>
                        <td>{row.id}</td>
                        <td>
                           {row.logo == null ?
                              <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                              :
                              <img src={"https://telehconsult.com/telehconsult/Cate_logo/" + row.logo} title="Category Image" alt="" />
                           }
                        </td>
                        <td>{row.name}</td>
                        <td>
                           <div className='patient_status'>
                              {row.status}
                           </div>
                        </td>

                        <td>
                           <span className='transfer_btn'>
                              <button onClick={() => open_editprime_modal(row.id, row.name, row.logo)}>Edit</button>
                              <button onClick={() => open_delete_modal(row.id)}>Delete</button>
                           </span>
                        </td>
                     </tr>
                  ))}
               </table>
            </div>
         </div>

         <Primary_categories_Paginations totalPosts={refunds.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />

         <Modal size='lg' isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
            <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
               Delete Category
            </ModalHeader>

            <ModalBody>
               <div className='refund_modal'>
                  <h4>Are you sure want to delete this category.</h4>
                  {patLoading ?
                     <button id='refund_loader'>
                        <PulseLoader
                           color={"white"}
                           loading={patLoading}
                           //  size={5}
                           aria-label="Loading Spinner"
                           data-testid="loader"
                        />
                     </button>
                     :
                     <button onClick={delete_primarycategory}>Done</button>
                  }
               </div>
            </ModalBody>
         </Modal>



         <Modal size='lg' isOpen={add_primary} toggle={() => setAdd_primary(!add_primary)}>
            <ModalHeader toggle={() => setAdd_primary(!add_primary)}>
               Add Primary Category
            </ModalHeader>

            <ModalBody>
               <div className='add_category_modal'>
                  <h4>Add Primary Category</h4>

                  <div className="category_name">
                     <div className='name_width'>
                        <div className='name_label'>
                           <label>Name</label>
                        </div>

                        <input type="text" className="input" id="subject" placeholder="Name" onChange={(e) => setName(e.target.value)} />
                     </div>
                  </div>

                  <div className='categoryImage'>
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="" className="fab fa-firstdraft" />
                     {/* <a href='' className='prfilebtn'>Edit</a> */}
                  </div>

                  <input type='file' onChange={fileSelectedHandler} />

                  {patLoading ?
                     <button id='refund_loader'>
                        <PulseLoader
                           color={"white"}
                           loading={patLoading}
                           //  size={5}
                           aria-label="Loading Spinner"
                           data-testid="loader"
                        />
                     </button>
                     :
                     <button onClick={connect}>Add Category</button>
                  }
               </div>
            </ModalBody>
         </Modal>



         <Modal size='lg' isOpen={edit_primary} toggle={() => setEdit_primary(!edit_primary)}>
            <ModalHeader toggle={() => setEdit_primary(!edit_primary)}>
               Edit Primary Category
            </ModalHeader>

            <ModalBody>
               <div className='add_category_modal'>
                  <h4>Edit Primary Category</h4>

                  <div className="category_name">
                     <div className='name_width'>
                        <div className='name_label'>
                           <label>Name</label>
                        </div>

                        <input type="text" className="input" id="subject" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                     </div>
                  </div>

                  <div className='categoryImage'>
                     {file == null ?
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="" className="fab fa-firstdraft" />
                        :
                        <img src={"https://telehconsult.com/telehconsult/Cate_logo/" + file} title="Category Image" alt="" className="fab fa-firstdraft" />
                     }
                  </div>

                  <input type='file' onChange={fileSelectedHandler} />

                  {patLoading ?
                     <button id='refund_loader'>
                        <PulseLoader
                           color={"white"}
                           loading={patLoading}
                           //  size={5}
                           aria-label="Loading Spinner"
                           data-testid="loader"
                        />
                     </button>
                     :
                     <button onClick={update_primarycategoty}>Update Category</button>
                  }
               </div>
            </ModalBody>
         </Modal>

      </div>
   );
}