import React, { useState } from 'react';
import './Drawer.css';

// https://source.unsplash.com/1600x900/?background
// https://source.unsplash.com/1600x900/?doctor
// className="text-center"
function App() {
  return (

<body>

    <div className="container">
        <div className="navigation">
          
            <ul>
                <li>
                    <a href="">
                        <span className="icon"><i className="bi bi-house" aria-hidden="true"></i></span>
                        <span className="title">Home</span>
                    </a>
                </li>

                <li>
                    <a href="">
                        <span className="icon"><i className="bi bi-person-lines-fill" aria-hidden="true"></i></span>
                        <span className="title">Profile</span>
                    </a>
                </li>

                <li>
                    <a href="">
                        <span className="icon"><i className="bi bi-chat-dots" aria-hidden="true"></i></span>
                        <span className="title">Message</span>
                    </a>
                </li>

                <li>
                    <a href="">
                        <span className="icon"><i className="bi bi-info-circle" aria-hidden="true"></i></span>
                        <span className="title">Help</span>
                    </a>
                </li>

                <li>
                    <a href="">
                        <span className="icon"><i className="bi bi-gear-wide-connected" aria-hidden="true"></i></span>
                        <span className="title">Setting</span>
                    </a>
                </li>

                <li>
                    <a href="">
                        <span className="icon"><i className="bi bi-lock-fill" aria-hidden="true"></i></span>
                        <span className="title">Password</span>
                    </a>
                </li>

                <li>
                    <a href="">
                        <span className="icon"><i className="bi bi-box-arrow-left"></i></span>
                        <span className="title">Sign Out</span>
                    </a>
                </li>
            </ul>

        </div>

        <div className="toggle"><i class="bi bi-chevron-right"></i></div>
    </div>

</body>

);
}

export default App;