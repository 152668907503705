import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import './Appointment.css';
import Appoi_Paginations from './Appoi_Paginations';
import Connection from "../../connection";
import Image_connection from "../../Image_connection";

export default function Appointment() {

   const [products, setProducts] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage, setPostsPerPage] = useState(5);

   const lastPostIndex = currentPage * postsPerPage
   const firstPostIndex = lastPostIndex - postsPerPage
   const currentPosts = products.slice(firstPostIndex, lastPostIndex)

   useEffect(() => {
      getProducts()
   }, [])
   const getProducts = async () => {
      let result = await fetch(Connection + "get_appointment", {

      })
      result = await result.json()
      setProducts(result)
      console.warn(result)
      console.log("tttotototototoot", JSON.stringify(localStorage.getItem('token')))

   }

   return (
      <div className='appoint_table_vie'>
         <div className='back_table'>
            <h2>APPOINTMENT</h2>
            <hr></hr>

            <table className='table'>
               <tr>
                  <th>ID</th>
                  <th>Provider</th>
                  <th>Patient</th>
                  {/* <th>Category</th> */}
                  <th>Date</th>
                  <th>Time</th>
                  <th>Day</th>
                  <th>Fee</th>
                  <th>Status</th>
               </tr>


               {currentPosts.map((row) => (
                  <tr>
                     <td>{row.id}</td>

                     <td>
                        {row.doctor_profile == null ?
                           <div>
                              <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                           </div>
                           :
                           <div>
                              <img src={Image_connection + row.doctor_profile} title="Profile Image" alt="" />
                           </div>
                        }
                        <p>{row.doctor_name}</p>
                     </td>

                     <td>
                        {row.user_profile == null ?
                           <div>
                              <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                           </div>
                           :
                           <div>
                              <img src={Image_connection + row.user_profile} title="Profile Image" alt="" />
                           </div>
                        }
                        <p>{row.user_name}</p>
                     </td>

                     {/* <td>{row.category}</td> */}
                     <td>{row.date}</td>
                     <td>{row.time}</td>
                     <td>{row.day}</td>
                     <td>₦{row.fee}</td>
                     <td>
                        <div className='status'>
                           {row.status}
                        </div>
                     </td>
                  </tr>
               ))}
            </table>
         </div>

         <Appoi_Paginations totalPosts={products.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />

         {/* <div>
               <ul>
                  <li><a href='#' className='prev'>Prev</a></li>
                  <li className='pagenumber active'><a href='#'>1</a></li>
                  <li className='pagenumber'><a href='#'>2</a></li>
                  <li className='pagenumber'><a href='#'>3</a></li>
                  <li className='pagenumber'><a href='#'>4</a></li>
                  <li className='pagenumber'><a href='#'>5</a></li>
                  <li><a href='#' className='next'>Next</a></li>
               </ul>
            </div> */}
      </div>
   );
}