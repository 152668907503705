import HomeIcon from '@mui/icons-material/Home';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
export const navData = [
    {
        id: 0,
        icon: <HomeIcon/>,
        text: "Dashboard",
        link: "/"
    },
    {
        id: 1,
        icon: <TravelExploreIcon/>,
        text: "Patient List",
        link: "Patient_list"
    },
    {
        id: 2,
        icon: <BarChartIcon/>,
        text: "Add Patient",
        link: "add_patient"
    },
    {
        id: 3,
        icon: <SettingsIcon/>,
        text: "Doctor List",
        link: "Doctor_list"
    },
    {
        id: 4,
        icon: <SettingsIcon/>,
        text: "Add Doctor",
        link: "add_doctor"
    },
    {
        id: 5,
        icon: <SettingsIcon/>,
        text: "Pending Doctor",
        link: "pending_doctor"
    },
    {
        id: 6,
        icon: <SettingsIcon/>,
        text: "Appointment",
        link: "Appointment"
    },
    {
        id: 7,
        icon: <SettingsIcon/>,
        text: "Pending Payout",
        link: "pending_payout"
    },
    {
        id: 8,
        icon: <SettingsIcon/>,
        text: "Refund",
        link: "refund"
    },
    {
        id: 9,
        icon: <SettingsIcon/>,
        text: "Transactions",
        link: "transactions"
    },
    {
        id: 10,
        icon: <SettingsIcon/>,
        text: "Appointment",
        link: "Appointment"
    },
    {
        id: 6,
        icon: <SettingsIcon/>,
        text: "Appointment",
        link: "Appointment"
    },
]