import React, {useState, useEffect,useRef } from 'react'
 import './Add_patient.css';
import { Modal,ModalHeader,ModalBody,Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import { Backdrop, CircularProgress } from '@material-ui/core';
import axios from "axios"
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import {BrowserRouter, Routes, Route, useNavigate, Link} from "react-router-dom"

export default function Add_patient() {

   const [name, setName]= useState("");
   const [email, setEmail]= useState("");
   const [password, setPassword]= useState("");
   const [mobile_number, setMobile_number]= useState("");
   const [address, setAddress]= useState("");

   const [modalsuccess, setModalsuccess] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const [file, setFile] = useState(null);
   const [fileName, setFileName] = useState(null);
   // const [fileInput, setFileInput] = useState("");
   const fileInput = useRef();
   
   const [error, setError]= useState(false);


   const navigat = useNavigate()

   const Add_Patient =async(event)=>{
       event.preventDefault();

       if(!name || !email || !password || !mobile_number || !address){
           setError(true)
           alert('Information is not complete');
           return false
       }
       setIsLoading(true);
   
       const role = "user"
       console.warn(name,email,password,mobile_number,address)
   
       let  result =await fetch(Connection + "add_Patient",{
           method:'post',
           body: JSON.stringify({name,email,password,mobile_number,address,role}),
           headers:{
               "Content-Type":'application/json'
           }
       })
       result=await result.json()
       console.log("result123",result)
       console.log("result.auth",result.auth)
   
       if(result){
          setIsLoading(false);
          setModalsuccess(true)
       }
   }

   const Succeess =async(event)=>{
      setModalsuccess(false)
      navigat('/Patient_list')
   }


   const resset = async (event) => {
      event.preventDefault();
      console.log("ok")

      setName("");
      setEmail("");
      setPassword("");
      setMobile_number("");
      setAddress("");
      setIsLoading("");
      setFile(null);
      setFileName(null);
   }

   // const fileuad = (event) => {
   //    event.preventDefault();
 
   //    // localStorage.clear();
   //    // navigat('/')

   //    const fd = new FormData;
   //    fd.append('image', selectedFile, selectedFile.name)
   //    axios.post('https://netbeans.tech/telehconsult/Chat_pdf', fd, {
   //       onUploadProgress: progressEvent => {
   //          console.log('Upload progress:' + Math.round(progressEvent.loaded / progressEvent.total * 100) + '%')
   //       }
   //    })
   //      .then(res => {
   //           console.log(res)
   //      });
   // }

   // const fileupload = (event) => {
   //    event.preventDefault();

   //    const fd = new FormData;

   //    fd.append('image', selectedFile, selectedFile.name)
   //    // fd.append('picture',selectedFile.current.files[0])
   //    var xhr = new XMLHttpRequest();
   //    xhr.open('POST',Connection + 'upload',true)

   //    xhr.send(FormData)
   // }


   const fileSelectedHandler = (e) => {
      // console.log("sdkslkdlk",event.target.files[0]);
      // console.log("sdkslkdlksddvfvf",event.target.files[0].name);

      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
   console.log("sdkslkdlksddvfvf",e.target.files[0]);

  }


  const uploadFilek = async (event) => {
   event.preventDefault();
   console.log("file1",file)
   console.log("fileName1",fileName)
   const formData = new FormData();
   formData.append("file", file);
   formData.append("fileName", fileName);
   try {
     const res = await axios.post(
      Connection + "upload_img",
       formData
     );
     console.log(res);
   } catch (ex) {
     console.log(ex);
   }
 };



 const submit = async (event) =>{
   event.preventDefault();

   const formdata = new FormData(); 
  
   formdata.append("file", file);

   formdata.append("fileName", fileName);


   axios.post( Connection + "upload_img", formdata )
   .then(res => { // then print response status
     console.warn(res);
   //   if(res.data.success === 1){
   //     setSuccess("Image upload successfully");
   //   }

   })
 }




 const connect=async (event)=>{

   event.preventDefault();
   console.log("connect_file1",file)
   console.log("connect_fileName1",fileName)

const form_data = new FormData();
form_data.append("profg", file);
 
 const api = Connection + "upload_img"
const result =await fetch(api, {
    method: "POST",
    body: form_data
  
});

// result=await result.json()
console.log("result123",result)
 }


//    const Add_picture =async(event)=>{
//       event.preventDefault();

//       let uploaddata = new FormData();

//       console.log("file",file)
//       console.log("fileName","profg")
     
//       uploaddata.append('file', file);
//       uploaddata.append('fileName', "profg");

//       // uploaddata.append('fileName1', fileName);

//       let result =await fetch(Connection + "upload_img",{
//           method:'post',
//           body: uploaddata,
//           headers:{
//              "Content-Type":"multipart/form-data"
//           }
//       })
//       result=await result.json()
//       console.log("result123",result)
//   }


   const uploadFile = async (e) => {
      console.log("ssss",file)
      console.log("ssss",fileName)

        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        try {
          const res = await axios.post(
            Connection + 'upload_img',
            formData
          );
          console.log(res);
        } catch (ex) {
          console.log(ex);
        }
   };

   return (
        <div className='firstinpu'>
           
            <form className='Form-all-view'>
               <h2>ADD PATIENT</h2>
               <hr></hr>

               {/* <div className='profileImage'>
                   <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="" className="fab fa-firstdraft" />
                   <a href='' className='prfilebtn'>Add Profile</a>
                      <input  type='file' onChange={fileSelectedHandler} ref={fileInput}/>
                      <button onClick={() => fileInput.click()}>File Picker</button>
                      <Link onClick={connect}>Upload</Link>
               </div> */}
     
               <div class="first-name">
                  <div className='width-both-name-email'>
                     <div className='width-name'>
                        <div className='subject-name'>
                           <label>Name</label>
                        </div>
                        <input type="text" class="" id="name" placeholder="Your Name" onChange={(e)=>setName(e.target.value)}/>
                     </div>

                     <div className='width-name'>
                        <div className='subject-name'>
                           <label>Email</label>
                        </div>
                        <input type="email" class="" id="email" placeholder="Your Email" onChange={(e)=>setEmail(e.target.value)}/>
                     </div>
                  </div>
               </div>

               <div class="second-subject">
                  <div className='width-subject'>
                     <div className='subject-name'>
                        <label>Address</label>
                     </div>
                     <input type="text" class="subject" id="subject" placeholder="Address" onChange={(e)=>setAddress(e.target.value)}/>
                  </div>
               </div>

               <div class="first-name">
                  <div className='width-both-name-email'>
                     <div className='width-name'>
                        <div className='subject-name'>
                           <label>Mobile Number</label>
                        </div>
                        <input type="text" class="" id="email" placeholder="Mobile Number" onChange={(e)=>setMobile_number(e.target.value)}/>
                     </div>
                     
                     <div className='width-name'>
                        <div className='subject-name'>
                           <label>Password</label>
                        </div>
                        <input type="text" class="" id="name" placeholder="Your Password" onChange={(e)=>setPassword(e.target.value)}/>
                     </div>
                  </div>
               </div>


 
               {/* <div class="second-subject">
                  <div className='width-subject'>
                     <div className='subject-name'>
                        <label>Message</label>
                     </div>
                     <textarea class="message" placeholder="Leave a message here" id="message" ></textarea>
                  </div>
               </div> */}

               <div class="patient_buttun">
                  <button onClick={resset} class="submit" id='reset' type="reset">Reset</button>
                  {isLoading ?
                      <button class="submit" id='loader' type="reset">
                          <PulseLoader
                               color={"white"}
                               loading={isLoading}
                              //  size={5}
                               aria-label="Loading Spinner"
                               data-testid="loader"
                          />
                     </button>
                   :
                     <button onClick={Add_Patient} class="submit" id='submite' type="submit">Submit</button>
                  }
               </div>
            </form>

            <Modal size='lg' isOpen={modalsuccess} toggle={() => setModalsuccess(!modalsuccess)}>
               <ModalHeader toggle={() => setModalsuccess(!modalsuccess)}>
                   Successfully
               </ModalHeader>

               <ModalBody>
                  <div className='succeess_modal'>
                      <h4>You have successfully Add Patient</h4>
                      <button onClick={Succeess}>Done</button>
                  </div>
               </ModalBody>
            </Modal>
        </div>
   );
}