import "./topbar.css";
import { NotificationsNone, Language, Settings } from "@material-ui/icons";
import React, {useState, useEffect } from 'react'

export default function Topbar() {

  const auth = localStorage.getItem("user");

  const [id, setId]= useState("");
  const [profile, setProfile]= useState("");

  useEffect(()=>{
    console.log("auth",auth)
    if (auth) {
       const parsed = JSON.parse(auth);
       const id = parsed[0].id;
       const profile1 = parsed[0].profile;
       const profile = "https://telehconsult.com/telehconsult/Admin_Image/" + profile1
    
       console.log("id",id)
       console.log("profile",profile)

       setId(id)
       setProfile(profile)
    }
    
 },[])

  return (
    <div className="topbar">
      <div className="topbarWrapper">
     
        <div className="topLeft">
          {/* <span className="logo">lamaadmin</span> */}
        </div>

        <div className="topRight">
          {/* <div className="topbarIconContainer">
            <NotificationsNone />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Language />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Settings />
          </div> */}
          {profile == null ?
             <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU" alt="" className="topAvatar" title="Profile Image" />
           :
             <img src={profile} alt="" className="topAvatar" title="Profile Image" />
          }
          {/* <img src="https://images.pexels.com/photos/1526814/pexels-photo-1526814.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500" alt="" className="topAvatar" /> */}
        </div>
        
      </div>
    </div>
  );
}
