import React, {useState, useEffect } from 'react'
import './Review_Manage.css';
import { Modal,ModalHeader,ModalBody,Row } from 'reactstrap';
import { Link, parsePath,useParams,useNavigate } from "react-router-dom"
import Paginations_Review_Manage from './Paginations_Review_Manage';
import PulseLoader from "react-spinners/PulseLoader";
import FadeLoader from "react-spinners/FadeLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import ReactStars from "react-rating-stars-component";

export default function Review_Manage() {

  const [modalreview, setModalreview] = useState(false);
  const [modalrevi_disapproved, setModalrevi_disapproved] = useState(false);

  const [Review, setReview]= useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(3);

  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentPosts = Review.slice(firstPostIndex, lastPostIndex)
  
  const [review_Id, setReview_Id] = useState('');

  const [patLoading, setPatLoading] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

    useEffect(()=>{
      getReview()
    },[])

    const getReview = async()=>{
        setPatLoading(true);
        let  result = await fetch(Connection + "getReview",{
           
        })
         result= await result.json()
         setReview(result)
         setPatLoading(false);
         console.warn(result)
    }

  
    //  Review Approved And Disapproved
     const Open_Approved_review = (id)=>{
        console.log("approved_review_id",id)
        setModalreview(true)
        setReview_Id(id)
     }
     
     const Open_Dis_Approved_review = (id)=>{
        console.log("dis_approved_review_id",id)
        setModalrevi_disapproved(true)
        setReview_Id(id)
     }

     const Approved_review =async(event)=>{
      event.preventDefault();

       setPatLoading(true);

       let result = await fetch(Connection + `approve_review/${review_Id}`)
       result=await result.json()
       console.log("result123",result)

       if(result){
         setPatLoading(false);
         setModalreview(false)
         getReview()
       }
     }
      

     const Dis_Approved_review =async(event)=>{
        event.preventDefault();
  
         setPatLoading(true);
  
         let result = await fetch(Connection + `disapprove_review/${review_Id}`)
         result=await result.json()
         console.log("result123",result)
  
         if(result){
             setPatLoading(false);
             setModalrevi_disapproved(false)
             getReview()
         }
       }
    ////////////////////////////

    return (
        <div className='table_review'>
           
            <div className='responsive_review'>
               <div className='head_card'>
                   <h2>REVIEWS</h2>
               </div>
              <hr></hr>

 
              <div className='ALL_Rview_Card'>

                     {patLoading ?
                        <div className='loader_review'>
                           <FadeLoader
                               color={"#29166f"}
                               loading={patLoading}
                               size={30}
                               aria-label="Loading Spinner"
                               data-testid="loader"
                           />
                           <p>Loading ...</p>
                        </div>
                        :
                        <>
                        {currentPosts == "" ?
                         <div className='Noreview'>
                            <h4>No Review Available</h4>
                         </div>
                        :
                        <>
                        {currentPosts.map((row) => (
                        <div className='review_all'>
                             {row.user_profile == null ?
                                <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt=""/>
                             :
                                <img src={Image_connection + row.user_profile} title="license Image" alt=""/>
                             }

                             <div className='review_details'>
                                <h3>{row.user_name}</h3>
 
                                <p>{row.comment}</p>

                                <div className='bottom_deatil_review'>
                                    <div style={{display: 'flex'}}>
                                      <h3>({row.rating})</h3>
                                      <ReactStars
                                         count={5}
                                         value={row.rating}
                                         size={24}
                                         edit={false}
                                         activeColor="#ffd700"
                                      />
                                    </div>

                                    <div>
                                      <button className='rev_approv' onClick={() => Open_Approved_review(row.id)}>Approved</button>
                                      <button className='rev_approv' onClick={() => Open_Dis_Approved_review(row.id)}>disapproved</button>
                                    </div>
                                </div>

                             </div>
                        </div>
                        ))}
                        </>
                        }
                        </>
                     }
                    
             </div>
        
            </div>

            <Paginations_Review_Manage totalPosts={Review.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>



            {/* Review Modal */}
            <Modal size='lg' isOpen={modalreview} toggle={() => setModalreview(!modalreview)}>
               <ModalHeader toggle={() => setModalreview(!modalreview)}>
                   APPROVAL
               </ModalHeader>

               <ModalBody>
                  <div className='delete_modal'>
                      <h4>Are you sure you want to approved this review?</h4>
                      {patLoading ?
                        <button id='delete_loader'>
                          <PulseLoader
                               color={"white"}
                               loading={patLoading}
                              //  size={5}
                               aria-label="Loading Spinner"
                               data-testid="loader"
                          />
                        </button>
                      :
                        <button onClick={Approved_review}>Approved</button>
                      }
                  </div>
               </ModalBody>
            </Modal>

            <Modal size='lg' isOpen={modalrevi_disapproved} toggle={() => setModalrevi_disapproved(!modalrevi_disapproved)}>
               <ModalHeader toggle={() => setModalrevi_disapproved(!modalrevi_disapproved)}>
                   APPROVAL
               </ModalHeader>

               <ModalBody>
                  <div className='delete_modal'>
                      <h4>Are you sure you want to disapproved this review?</h4>
                      {patLoading ?
                        <button id='delete_loader'>
                          <PulseLoader
                               color={"white"}
                               loading={patLoading}
                               //  size={5}
                               aria-label="Loading Spinner"
                               data-testid="loader"
                          />
                        </button>
                      :
                        <button onClick={Dis_Approved_review}>Disapproved</button>
                      }
                  </div>
               </ModalBody>
            </Modal>
            {/*  */}
         </div>
    );
}