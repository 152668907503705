import React, {useState, useEffect } from 'react'
import './Admin_List.css';
import { Modal,ModalHeader,ModalBody,Row } from 'reactstrap';
import { Link, parsePath,useParams } from "react-router-dom"
import Admin_List_Paginations from './Admin_List_Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";

export default function Admin_List() {

  const [admin, setAdmin]= useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentPosts = admin.slice(firstPostIndex, lastPostIndex)

  const [modalDelete, setModalDelete] = useState(false);
  
  const [id, setId] = React.useState("");
  
  const [patLoading, setPatLoading] = useState(false);

  const params = useParams();

    useEffect(()=>{
        get_admin()
    },[])
    const get_admin= async()=>{
        let  result =await fetch(Connection + "get_admin",{
           
        })
        result= await result.json()
         setAdmin(result)
         console.warn(result)
    }


   const open_delete_modal = (id) => {
      setModalDelete(true)

      setId(id)
   }
 

   const Delete_admin =async(event)=>{
    event.preventDefault();

     setPatLoading(true);

     let result = await fetch(Connection + `delete_admin/${id}`)
     result=await result.json()
     console.log("result123",result)

     if(result){
        setPatLoading(false);
        setModalDelete(false)
        get_admin()
     }
  }
 
    return (
        <div className='admin_list_category_main'>
           
            <div className='respons_admin_list_card'>
               <div className='head_card'>
                   <h2>Admin List</h2>
               </div>
              <hr></hr>
 
              <div className='primary_table'>
                <table className='admin_list_tble_tag'>
                           <tr>
                               <th>#</th>
                               <th>Email</th>
                               <th>Password</th>
                               <th>Category</th>
                               <th>Action</th>
                           </tr>


                      {currentPosts.map((row) => (
                           <tr>
                               <td>{row.id}</td>
                               <td>{row.email}</td>
                               <td>{row.password}</td>
                               <td>
                                  <div className='patient_status'>
                                    {row.category}
                                  </div>
                               </td>

                               <td>
                                  <span className='admin_transfe_btn'>
                                       <button onClick={() => open_delete_modal(row.id)}>Delete</button>
                                  </span>
                               </td>
                           </tr>
                      ))}
                </table>
             </div>
            </div>

            <Admin_List_Paginations totalPosts={admin.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>

            <Modal size='lg' isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
               <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
                  Delete Admin
               </ModalHeader>

               <ModalBody>
                  <div className='refund_modal'>
                      <h4>Are you sure want to delete this admin.</h4>
                      {patLoading ?
                        <button id='refund_loader'>
                          <PulseLoader
                               color={"white"}
                               loading={patLoading}
                              //  size={5}
                               aria-label="Loading Spinner"
                               data-testid="loader"
                          />
                        </button>
                      :
                       <button onClick={Delete_admin}>Done</button>
                      }
                  </div>
               </ModalBody>
            </Modal>

 
            
         </div>
    );
}