import React, { useState, useEffect } from 'react'
import './Secondary_categories.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Link, parsePath, useParams } from "react-router-dom"
import Secondary_categories_Paginations from './Secondary_categories_Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";

export default function Secondary_categories() {

   const [refunds, setRefunds] = useState([]);

   const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage, setPostsPerPage] = useState(5);

   const lastPostIndex = currentPage * postsPerPage
   const firstPostIndex = lastPostIndex - postsPerPage
   const currentPosts = refunds.slice(firstPostIndex, lastPostIndex)

   const [modalrefund, setModalrefund] = useState(false);
   const [add_secondary, setAdd_secondary] = useState(false);
   const [edit_secondary, setEdit_secondary] = useState(false);

   const [primary_categories, setPrimary_categories] = useState([]);

   const [id, setId] = React.useState("");
   const [name, setName] = React.useState("");
   const [file, setFile] = useState(null);
   const [old_file, setOld_file] = useState(null);
   const [primary_category, setPrimary_category] = useState("");

   const [patLoading, setPatLoading] = useState(false);

   const params = useParams();

   useEffect(() => {
      get_subcategory()
      get_primary_categories()
   }, [])


   const get_subcategory = async () => {
      let result = await fetch(Connection + "get_categories1", {})
      result = await result.json()
      setRefunds(result)
      console.warn(result)
   }


   const get_primary_categories = async () => {
      let result = await fetch(Connection + "primary_categories", {})
      result = await result.json()

      setPrimary_categories(result)
   }

   const open_delete_modal = (id) => {
      setModalrefund(true)

      setId(id)
   }


   const fileSelectedHandler = (e) => {
      setFile(e.target.files[0]);
      console.log("sdkslkdlksddvfvf", e.target.files[0]);
   }


   const connect = async (event) => {

      event.preventDefault();
      console.log("connect_file1", file)
      console.log("name", name)
      setPatLoading(true);

      const form_data = new FormData();

      form_data.append("name", name);
      form_data.append("profgg", file);
      form_data.append("primary_category", primary_category);

      const api = Connection + "upload_img1"
      const result = await fetch(api, {
         method: "POST",
         body: form_data,
      })
         .then((result) => result.json())
         .then((result) => {
            console.log("result", result);
            setPatLoading(false);
            setAdd_secondary(false)
            get_subcategory()
         })

         .catch((error) => {
            console.error(error);
            setPatLoading(false);
            alert("Something went wrong")
         });

      console.log("result123", result)
   }

   const delete_secondarycategory = async (event) => {
      event.preventDefault();

      setPatLoading(true);

      let result = await fetch(Connection + `delete_categories/${id}`)
      result = await result.json()
      console.log("result123", result)

      if (result) {
         setPatLoading(false);
         setModalrefund(false)
         get_subcategory()
      }
   }


   const open_editsecondr_modal = (id, p_name, name, logo) => {
      setEdit_secondary(true)

      setId(id)
      setName(name)
      setPrimary_category(p_name)
      setFile(logo)
      setOld_file(logo)
   }


   const update_secondarycategoty = async (event) => {
      if (old_file == file) {
         alert("Please make complete changes before updating.");
      }
      else {
         event.preventDefault();
         console.log("connect_file1", file)
         console.log("name", name)
         setPatLoading(true);

         const form_data = new FormData();

         form_data.append("id", id);
         form_data.append("name", name);
         form_data.append("edit_profgg", file);
         form_data.append("primary_category", primary_category);

         const api = Connection + "edit_secondary_category"
         const result = await fetch(api, {
            method: "POST",
            body: form_data,
         })
            .then((result) => result.json())
            .then((result) => {
               console.log("result", result);
               setPatLoading(false);
               setEdit_secondary(false)
               get_subcategory()
            })

            .catch((error) => {
               console.error(error);
               setPatLoading(false);
               alert("Something went wrong")
            });

         console.log("result123", result)
      }
   }


   return (
      <div className='secondary_main_table'>

         <div className='respons_secondary_card'>
            <div className='head_card'>
               <h2>Secondary Categories</h2>
               <button onClick={() => setAdd_secondary(true)}>Add Category</button>
            </div>
            <hr></hr>

            <div className='secondary_table'>
               <table className='seconda_tble_tag'>
                  <tr>
                     <th>#</th>
                     <th>Category Image</th>
                     <th>Primary Category</th>
                     <th>Name</th>
                     <th>Status</th>
                     <th>Action</th>
                  </tr>


                  {currentPosts.map((row) => (
                     <tr>
                        <td>{row.id}</td>
                        <td>
                           {row.logo == null ?
                              <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                              :
                              <img src={"https://telehconsult.com/telehconsult/Cate_logo/" + row.logo} title="Category Image" alt="" />
                           }
                        </td>
                        <td>{row.p_name}</td>
                        <td>{row.name}</td>
                        <td>
                           <div className='patient_status'>
                              {row.status}
                           </div>
                        </td>
                        <td>
                           <span className='transfer_btn'>
                              <button onClick={() => open_editsecondr_modal(row.id, row.p_name, row.name, row.logo)}>Edit</button>
                              <button onClick={() => open_delete_modal(row.id)}>Delete</button>
                           </span>
                        </td>
                     </tr>
                  ))}
               </table>
            </div>
         </div>

         <Secondary_categories_Paginations totalPosts={refunds.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />

         <Modal size='lg' isOpen={modalrefund} toggle={() => setModalrefund(!modalrefund)}>
            <ModalHeader toggle={() => setModalrefund(!modalrefund)}>
               Delete Category
            </ModalHeader>

            <ModalBody>
               <div className='secondary_modal'>
                  <h4>Are you sure want to delete this category.</h4>
                  {patLoading ?
                     <button id='refund_loader'>
                        <PulseLoader
                           color={"white"}
                           loading={patLoading}
                           //  size={5}
                           aria-label="Loading Spinner"
                           data-testid="loader"
                        />
                     </button>
                     :
                     <button onClick={delete_secondarycategory}>Delete</button>
                  }
               </div>
            </ModalBody>
         </Modal>



         <Modal size='lg' isOpen={add_secondary} toggle={() => setAdd_secondary(!add_secondary)}>
            <ModalHeader toggle={() => setAdd_secondary(!add_secondary)}>
               Add Secondary Category
            </ModalHeader>

            <ModalBody>
               <div className='secondary_modal'>
                  <h4>Add Secondary Category</h4>

                  <div className="category_name">
                     <div className='name_width'>
                        <div className='name_label'>
                           <label>Name</label>
                        </div>

                        <input type="text" className="input" id="subject" placeholder="Name" onChange={(e) => setName(e.target.value)} />
                     </div>
                  </div>

                  <div className='update_width_name'>
                     <div className='updatsubject_name'>
                        <label>Primary Category</label>
                     </div>

                     <select id="name" onChange={(e) => setPrimary_category(e.target.value)}>
                        <option>Primary Category</option>
                        {primary_categories.map((option, i) =>
                           <option value={option.name}>{option.name}</option>)}
                     </select>
                  </div>

                  <div className='categoryImage'>
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="" className="fab fa-firstdraft" />
                     {/* <a href='' className='prfilebtn'>Edit</a> */}
                  </div>

                  <input type='file' onChange={fileSelectedHandler} />

                  {patLoading ?
                     <button id='refund_loader'>
                        <PulseLoader
                           color={"white"}
                           loading={patLoading}
                           //  size={5}
                           aria-label="Loading Spinner"
                           data-testid="loader"
                        />
                     </button>
                     :
                     <button onClick={connect}>Add Category</button>
                  }
               </div>
            </ModalBody>
         </Modal>



         <Modal size='lg' isOpen={edit_secondary} toggle={() => setEdit_secondary(!edit_secondary)}>
            <ModalHeader toggle={() => setEdit_secondary(!edit_secondary)}>
               Edit Secondary Category
            </ModalHeader>

            <ModalBody>
               <div className='secondary_modal'>
                  <h4>Edit Secondary Category</h4>

                  <div className="category_name">
                     <div className='name_width'>
                        <div className='name_label'>
                           <label>Name</label>
                        </div>

                        <input type="text" className="input" id="subject" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                     </div>
                  </div>

                  <div className='update_width_name'>
                     <div className='updatsubject_name'>
                        <label>Primary Category</label>
                     </div>

                     <select id="name" onChange={(e) => setPrimary_category(e.target.value)}>
                        <option>{primary_category}</option>
                        {primary_categories.map((option, i) =>
                           <option value={option.name}>{option.name}</option>)}
                     </select>
                  </div>

                  <div className='categoryImage'>
                     {file == null ?
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="" className="fab fa-firstdraft" />
                        :
                        <img src={"https://telehconsult.com/telehconsult/Cate_logo/" + file} title="Category Image" alt="" className="fab fa-firstdraft" />
                     }
                  </div>

                  <input type='file' onChange={fileSelectedHandler} />

                  {patLoading ?
                     <button id='refund_loader'>
                        <PulseLoader
                           color={"white"}
                           loading={patLoading}
                           //  size={5}
                           aria-label="Loading Spinner"
                           data-testid="loader"
                        />
                     </button>
                     :
                     <button onClick={update_secondarycategoty}>Update Category</button>
                  }
               </div>
            </ModalBody>
         </Modal>

      </div>
   );
}