import React, {useState, useEffect } from 'react'
import { Modal,ModalHeader,ModalBody,Row } from 'reactstrap';
import { Link, parsePath,useParams,useNavigate } from "react-router-dom" 
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection"; 

export default function Pending_payout() {
  
  const [modalsuccess, setModalsuccess] = useState(false);
  const [percentage, setPercentage]= useState([]);
  const [error, setError]= useState(false);
  
  const [id, setId] = React.useState("");

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const params = useParams();

    useEffect(()=>{
    },[])
 

    const Add_percentage =async(event)=>{
        event.preventDefault();
        if(!percentage){
             setError(true)
             alert('Please enter Percentage.');
             return false
        }
        else{
         setIsLoading(true);
     
         console.warn(percentage)
     
         let  result =await fetch(Connection + "Add_percentage",{
             method:'post',
             body: JSON.stringify({percentage}), 
             headers:{
                 "Content-Type":'application/json'
             }
         })
         
         result=await result.json()
         console.log("result123",result)
         console.log("result.auth",result.auth)
     
         if(result){
            setIsLoading(false);
            setModalsuccess(true)
         }
        }
    }

    const Succeess =async(event)=>{
        setModalsuccess(false)
        navigate('/')
      }
     

    return (
        <div className='Pending_payout_table'>
           
            <div className='respons_Pending_payout_card'>
               <div className='head_card'>
                   <h2>Percentage</h2>
               </div>
              <hr></hr>
 
        
              <div class="second-subject">
                  <div className='width-subject'>
                     <div className='subject-name'>
                        <label>Percentage</label>
                     </div>
                     <input type="text" class="subject" id="subject" placeholder="Enter Percentage" onChange={(e)=>setPercentage(e.target.value)}/>
                  </div>
               </div>

               <div className="buttun-center">
                  {isLoading ?
                      <button class="submit" id='loader' type="reset">
                          <PulseLoader
                               color={"white"}
                               loading={isLoading}
                              //  size={5}
                               aria-label="Loading Spinner"
                               data-testid="loader"
                          />
                     </button>
                   :
                     <button onClick={Add_percentage} className="submit" id='submite'>Submit</button>
                  }
               </div>
            </div>

            <Modal size='lg' isOpen={modalsuccess} toggle={() => setModalsuccess(!modalsuccess)}>
               <ModalHeader toggle={() => setModalsuccess(!modalsuccess)}>
                   Successfully
               </ModalHeader>

               <ModalBody>
                  <div className='succeess_modal'>
                      <h4>You have successfully Add Percentage</h4>
                      <button onClick={Succeess}>Done</button>
                  </div>
               </ModalBody>
            </Modal>
 
         </div>
    );
}