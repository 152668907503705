import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import './Doctor_list.css';
import FadeLoader from "react-spinners/FadeLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";

export default function Doctor_list() {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getProducts()
    }, [])
    const getProducts = async () => {
        setIsLoading(true);
        let result = await fetch(Connection + "get_doctor", {

        })
        result = await result.json()
        setProducts(result)
        setIsLoading(false);

        console.warn(result)
    }

    return (
        <React.Fragment>
            <div className='main_view'>
                <h2>PROVIDER LIST</h2>

                {/* <div className='all_kard'>
                <div className='displayblock'>
                    <div className='doctor_card'>
                        <img src="https://t3.ftcdn.net/jpg/02/60/04/08/360_F_260040863_fYxB1SnrzgJ9AOkcT0hoe7IEFtsPiHAD.jpg" title="Profile Image" alt=""/>
                 
                        <div className='list_in_card'>
                             <h4>Tamarind</h4>
                             <h6>Comoridate Potraite</h6>
                             <div className='star'>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                             </div>

                             <h6>Thanks for call the services</h6>
                             <h6>No doubt best hospital</h6>
                        </div>
                    </div>
                </div>


                <div className='displayblock'>
                    <div className='doctor_card'>
                        <img src="https://t3.ftcdn.net/jpg/02/60/04/08/360_F_260040863_fYxB1SnrzgJ9AOkcT0hoe7IEFtsPiHAD.jpg" title="Profile Image" alt=""/>
                 
                        <div className='list_in_card'>
                             <h4>Tamarind</h4>
                             <h6>Comoridate Potraite</h6>
                             <div className='star'>
                                   <i class="bi bi-star-fill"></i>
                                   <i class="bi bi-star-fill"></i>
                                   <i class="bi bi-star-fill"></i>
                                   <i class="bi bi-star-fill"></i>
                                   <i class="bi bi-star-fill"></i>
                             </div>

                             <h6>Thanks for call the services</h6>
                             <h6>No doubt best hospital</h6>
                        </div>
                    </div>
                </div>
            </div> */}


                {/* title="Profile Image" alt="" */}
                {isLoading ? (
                    <div className='loader_doctor_list'>
                        <FadeLoader
                            color={"#29166f"}
                            loading={isLoading}
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p>Loading ...</p>
                    </div>
                )
                    :
                    (
                        <div className='all_kard'>
                            {products.map((row) => (
                                <div className='displayblock'>
                                    <div className='doctor_cad_2'>
                                        {row.profile == null ?
                                            <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                            :
                                            <img src={Image_connection + row.profile} title="Profile Image" alt="" />
                                        }

                                        <div className='list_in_cad_2'>
                                            <h6>{row.name}</h6>

                                            <h5>₦{row.fee}</h5>
                                            <h5>{row.email}</h5>
                                            <h4>{row.mobile_number}</h4>

                                        </div>
                                        <span className='book_appo'>
                                            <Link to={"/Doctor_Details/" + row.id}>Provider Profile</Link>
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

            </div>
        </React.Fragment>
    );
}