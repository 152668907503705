import React, { useState, useEffect } from 'react'
import './Refund.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Link, parsePath, useParams } from "react-router-dom"
import Refund_Paginations from './Refund_Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";

export default function Refund() {

  const [refunds, setRefunds] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentPosts = refunds.slice(firstPostIndex, lastPostIndex)

  const [modalrefund, setModalrefund] = useState(false);

  const [id, setId] = React.useState("");
  const [bal, setbal] = React.useState("");

  const [patLoading, setPatLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    get_refunds()
  }, [])
  const get_refunds = async () => {
    let result = await fetch(Connection + "get_refund", {

    })
    result = await result.json()
    setRefunds(result)
    console.warn(result)
  }


  const open_refund_modal = (user_id, total_fee) => {
    setModalrefund(true)

    setId(user_id)
    setbal(total_fee)
  }


  const refund_done = async (event) => {
    event.preventDefault();

    setPatLoading(true);

    let result = await fetch(Connection + `transfer_refund/${id}`, {
      method: 'post',
      body: JSON.stringify({ bal }),
      headers: {
        "Content-Type": 'application/json'
      }
    })

    result = await result.json()
    console.log("result123", result)

    if (result) {
      setPatLoading(false);
      setModalrefund(false)
      get_refunds()
    }
  }

  return (
    <div className='refund_main_table'>

      <div className='respons_refund_card'>
        <div className='head_card'>
          <h2>Refund</h2>
        </div>
        <hr></hr>

        <div className='refund_table'>
          <table className='refun_tble_tag'>
            <tr>
              <th>Appointment Id</th>
              <th>User Id</th>
              <th>User Name</th>
              <th>Provider Id</th>
              <th>Total Fee</th>
              <th>Refund</th>
              <th>Status</th>
              <th>Date</th>
              <th>Action</th>
            </tr>


            {currentPosts.map((row) => (
              <tr>
                <td>{row.appointment_id}</td>
                <td>{row.user_id}</td>
                <td>{row.user_name}</td>
                <td>{row.doctor_id}</td>
                <td>₦{row.total_fee}</td>
                <td>₦{row.r_amount}</td>
                <td>{row.status}</td>
                <td>{row.date}</td>
                <td>
                  <span className='transfer_btn'>
                    <button onClick={() => open_refund_modal(row.user_id, row.total_fee)}>Transferred</button>
                  </span>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>

      <Refund_Paginations totalPosts={refunds.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />

      <Modal size='lg' isOpen={modalrefund} toggle={() => setModalrefund(!modalrefund)}>
        <ModalHeader toggle={() => setModalrefund(!modalrefund)}>
          Refund
        </ModalHeader>

        <ModalBody>
          <div className='refund_modal'>
            <h4>Are you sure, You want to refund.</h4>
            {patLoading ?
              <button id='refund_loader'>
                <PulseLoader
                  color={"white"}
                  loading={patLoading}
                  //  size={5}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </button>
              :
              <button onClick={refund_done}>Done</button>
            }
          </div>
        </ModalBody>
      </Modal>

    </div>
  );
}