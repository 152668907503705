import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import './Add_Admin.css';
import Multiselect from 'multiselect-react-dropdown';
import { GlobalInfo } from "../../App"

export default function Add_Admin() {

   const { appColor, getdate, date } = useContext(GlobalInfo)
   const dateeee = "monday"

   const [file, setFile] = useState(null);
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [category, setCategory] = useState("");
   const [multitab_butn, setMultitab_butn] = useState(null);
   const options = [
      { key: 'option1', value: 'Dashboard' },
      { key: 'option2', value: 'Add Admin' },
      { key: 'option3', value: 'Admin List' },
      { key: 'option4', value: 'Patient List' },
      { key: 'option5', value: 'Add Patient' },
      { key: 'option6', value: 'Provider List' },
      { key: 'option7', value: 'Add Provider' },
      { key: 'option8', value: 'Pending Provider' },
      { key: 'option9', value: 'Primary Categories' },
      { key: 'option10', value: 'Secondary Categories' },
      { key: 'option11', value: 'Review Manage' },
      { key: 'option12', value: 'Appointment' },
      { key: 'option13', value: 'Pending Payout' },
      { key: 'option14', value: 'Refund' },
      { key: 'option15', value: 'Transactions' },
      { key: 'option16', value: 'Percentage' },
   ];

   const [just_Show, setJust_Show] = useState(null);

   const [modalsuccess, setModalsuccess] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const [error, setError] = useState(false);

   const navigate = useNavigate();


   function fileSelectedHandler(event) {
      const file = event.target.files[0];
      setFile(event.target.files[0]);
      const reader = new FileReader();
      reader.onload = function (event) {
         const imageUrl = event.target.result;
         setJust_Show(imageUrl);
      };
      reader.readAsDataURL(file);
   }


   //  const AddAdmin = async (event)=>{
   //     event.preventDefault();

   //     if(!file){
   //         setError(true)
   //         alert('Please select admin profile.');
   //         return false
   //     }
   //     if(!email){
   //         setError(true)
   //         alert('Please enter admin email.');
   //         return false
   //     }
   //     if(!password){
   //         setError(true)
   //         alert('Please enter admin password.');
   //         return false
   //     }
   //     if(!category){
   //       setError(true)
   //       alert('Please enter admin speciality.');
   //       return false
   //     }

   //     console.log("connect_file1",file)
   //     console.log("email",email)
   //     console.log("password",password)
   //     console.log("speciality",category)

   //     setIsLoading(true);

   //     const form_data = new FormData();

   //     form_data.append("email", email);
   //     form_data.append("password", password);
   //     form_data.append("admin", file);
   //     form_data.append("category", category);

   //      const api = Connection + "Add_Admin"
   //      const result =await fetch(api, {
   //          method: "POST",
   //          body: form_data,  
   //      })
   //      .then((result) => result.json())
   //      .then((result) => {
   //           console.log("result", result);
   //           setIsLoading(false);
   //           setModalsuccess(true)
   //      })

   //      .catch((error) => {
   //           console.error(error);
   //           setIsLoading(false);
   //           alert("Something went wrong")
   //       });

   //       console.log("result123",result)
   //  }



   const AddAdmin = async (event) => {
      event.preventDefault();

      if (!email) {
         setError(true)
         alert('Please enter admin email.');
         return false
      }
      if (!password) {
         setError(true)
         alert('Please enter admin password.');
         return false
      }
      if (!category) {
         setError(true)
         alert('Please select admin speciality.');
         return false
      }
      if (!multitab_butn) {
         setError(true)
         alert('Please select admin tabs.');
         return false
      }

      setIsLoading(true);

      console.log("email", email)
      console.log("password", password)
      console.log("speciality", category)

      var side_tab = JSON.stringify(multitab_butn)
      console.log("side_tab => ", side_tab)

      let result = await fetch(Connection + "Add_Admin", {
         method: 'post',
         body: JSON.stringify({ email, password, category, side_tab }),
         headers: {
            "Content-Type": 'application/json'
         }
      })
      result = await result.json()
      console.log("result123", result)
      console.log("result.auth", result.auth)

      if (result) {
         setIsLoading(false);
         setModalsuccess(true)
      }
   }

   const Succeess = async (event) => {
      event.preventDefault();
      setModalsuccess(false)
      navigate('/Admin_List')
   }




   const onSelect = (selectedList, selectedItem) => {
      console.log("sds", selectedList)
      var names = selectedList.map(function (i) {
         return i.value;
      });
      console.log("names => ", names)

      setMultitab_butn(names)
   }

   const onRemove = (selectedList, removedItem) => {
      console.log("sds")
   }



   return (
      <div className='firstinpu'>
         {/* <button onClick={() => getdate(dateeee)}>Click</button>
            <h2>{date}</h2> */}

         <form className='Form-all-view'>
            <h2>ADD ADMIN</h2>
            <hr></hr>

            {/* <div className='profileImage'>
                   {just_Show == null ?
                     <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="" className="fab fa-firstdraft" />
                   :
                     <img src={just_Show} alt="" className="fab fa-firstdraft" />
                   }
                   <div className="custom_file_input">
                       <input type="file" onChange={fileSelectedHandler} />
                       <label>Add Profile</label>
                   </div>
               </div> */}

            <div className="first-name">
               <div className='width-both-name-email'>
                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Email</label>
                     </div>
                     <input type="text" className="" id="name" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                  </div>


                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Password</label>
                     </div>
                     <input type="password" className="" id="email" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                  </div>
               </div>
            </div>

            <div className="second-subject">
               <div className='width-subject'>
                  <div className='subject-name'>
                     <label>Category</label>
                  </div>
                  <select id="subject" onChange={(e) => setCategory(e.target.value)}>
                     <option value="">--Please choose an option--</option>
                     <option value="Administrator">Administrator</option>
                     <option value="Accountant">Accountant</option>
                  </select>
               </div>
            </div>


            <div className="second-subject">
               <div className='width-subject'>
                  <div className='subject-name'>
                     <label>Choose Tab Options</label>
                  </div>
                  <Multiselect
                     placeholder="Tabs"
                     options={options}
                     emptyRecordMsg="Select Tab Options"
                     //  selectedValues={"Select category"} // Preselected value to persist in dropdown
                     onSelect={onSelect} // Function will trigger on select event
                     onRemove={onRemove} // Function will trigger on remove event
                     displayValue="value" // Property name to display in the dropdown options
                  />
               </div>
            </div>


            <div className="buttun-center">
               <button className="submit" id='reset' type="reset">Reset</button>
               {isLoading ?
                  <button class="submit" id='loader'>
                     <PulseLoader
                        color={"white"}
                        loading={isLoading}
                        //  size={5}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                     />
                  </button>
                  :
                  <button onClick={AddAdmin} className="submit" id='submite'>Submit</button>
               }
            </div>
         </form>

         <Modal size='lg' isOpen={modalsuccess} toggle={() => setModalsuccess(!modalsuccess)}>
            <ModalHeader toggle={() => setModalsuccess(!modalsuccess)}>
               Successfully
            </ModalHeader>

            <ModalBody>
               <div className='succeess_modal'>
                  <h4>You have successfully Add Admin</h4>
                  <button onClick={Succeess}>Done</button>
               </div>
            </ModalBody>
         </Modal>
      </div>
   );
}