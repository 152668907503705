import React, { useState, useEffect, } from 'react'
import { Link, parsePath, useParams, useNavigate } from "react-router-dom"
import './Doctor_Details.css';
import GoogleMapReact from 'google-map-react';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import ReactStars from "react-rating-stars-component";

export default function Doctor_Details() {
    const [text1, setText1] = React.useState(true);
    const [text2, setText2] = React.useState(false);
    const [text3, setText3] = React.useState(false);
    const [text4, setText4] = React.useState(false);
    const [text5, setText5] = React.useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalapproved, setModalapproved] = useState(false);
    const [modal_disapproved, setModal_disapproved] = useState(false);

    const [name, setName] = React.useState("");
    const [category, setCategory] = React.useState("");
    const [primary_categories, setPrimary_categories] = React.useState("");
    const [consult_now, setConsult_now] = React.useState("");

    const [mobile_number, setMobile_number] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [user_state, setUser_state] = React.useState("");

    const [gender, setGender] = React.useState("");
    const [experience, setExperience] = React.useState("");
    const [degree, setDegree] = React.useState("");
    const [about, setAbout] = React.useState("");
    const [license_number, setLicense_number] = React.useState("");
    const [profile, setProfile] = React.useState("");
    const [license_image, setIicense_image] = React.useState("");
    const [fee, setFee] = React.useState("");
    const [status, setStatus] = React.useState("");

    const [patLoading, setPatLoading] = useState(false);
    const [reviews, setReviews] = useState(false);
    const [user_profile, setUser_profile] = useState(null);


    const params = useParams();
    const navigate = useNavigate();

    // Change Btn
    const openText1 = (event) => {
        event.preventDefault();
        setText1(true);
        setText2(false);
        setText3(false);
        setText4(false);
        setText5(false);
    };
    const openText2 = (event) => {
        event.preventDefault();
        setText2(true);
        setText1(false);
        setText3(false);
        setText4(false);
        setText5(false);
    };
    const openText3 = (event) => {
        event.preventDefault();
        setText3(true);
        setText1(false);
        setText2(false);
        setText4(false);
        setText5(false);
    };
    const openText4 = (event) => {
        event.preventDefault();
        setText4(true);
        setText1(false);
        setText2(false);
        setText3(false);
        setText5(false);
    };
    const openText5 = (event) => {
        event.preventDefault();
        setText5(true);
        setText1(false);
        setText2(false);
        setText3(false);
        setText4(false);
    };



    useEffect(() => {
        getProducts()
        getReview()
    }, [])


    const getProducts = async () => {
        let result = await fetch(Connection + `one_doctor/${params.id}`, {

        })
        result = await result.json()
        setName(result[0].name)

        let category_1 = result[0].category
        let category_2 = JSON.parse(category_1);
        var category = category_2.join(","); //"red,blue,green"
        setCategory(category)

        setPrimary_categories(result[0].primary_category)
        setConsult_now(result[0].consult_now)

        setMobile_number(result[0].mobile_number)
        setEmail(result[0].email)
        setAddress(result[0].address)
        setUser_state(result[0].user_state)
        setGender(result[0].gender)
        setExperience(result[0].experience)
        setDegree(result[0].degree)
        setAbout(result[0].about)
        setLicense_number(result[0].license_number)
        setProfile(result[0].profile)
        setIicense_image(result[0].license_image)
        setFee(result[0].fee)
        setStatus(result[0].status)
        console.warn(name)
    }


    const getReview = async () => {
        let result = await fetch(Connection + `getReview_bydoctor/${params.id}`, {

        })
        result = await result.json()
        setUser_profile(result[0].user_profile)
        setReviews(result)
    }


    const Update_doctor = async (event) => {
        event.preventDefault();
        //  if(!name || !email || !mobile_number || !address || !city || !category || !consult_now || !fee){
        //      setError(true)
        //      alert('Information is not complete');
        //      return false
        //  }
        if (fee <= 1306) {
            alert('Provider fee should be greater');
            return false
        }

        setPatLoading(true);


        console.warn(name, email, mobile_number, fee, address, user_state, category, consult_now)

        let result = await fetch(Connection + `update_doctor/${params.id}`, {
            method: 'post',
            body: JSON.stringify({ name, email, mobile_number, fee, address, user_state, category, consult_now }),
            headers: {
                "Content-Type": 'application/json'
            }
        })
        result = await result.json()
        console.log("result123", result)
        console.log("result.auth", result.auth)

        if (result) {
            getProducts()
            setPatLoading(false);
            //   navigate('/')
        }
    }

    const Delete_patient = async (event) => {
        event.preventDefault();

        setPatLoading(true);

        let result = await fetch(Connection + `delete_patient/${params.id}`)
        result = await result.json()
        console.log("result123", result)

        if (result) {
            setPatLoading(false);
            setModalDelete(false)
            navigate('/Doctor_list')
        }
    }


    const Approved_doctor = async (event) => {
        event.preventDefault();

        setPatLoading(true);

        let result = await fetch(Connection + `approve_doctor/${params.id}`)
        result = await result.json()
        console.log("result123", result)

        if (result) {
            setPatLoading(false);
            setModalapproved(false)
            navigate('/Pending_doctor')
        }
    }


    const Dis_Approved_doctor = async (event) => {
        event.preventDefault();

        setPatLoading(true);

        let result = await fetch(Connection + `disapprove_doctor/${params.id}`)
        result = await result.json()
        console.log("result123", result)

        if (result) {
            setPatLoading(false);
            setModal_disapproved(false)
            navigate('/Doctor_list')
        }
    }


    return (
        <div className='Doctor_head'>
            <h2>PROVIDER PROFILE</h2>

            <div className='All_in_this'>

                <div className='first_column'>
                    <div className='Profile_card'>
                        <div className='img-wrapper'>
                            {profile == null ?
                                <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                :
                                <img src={Image_connection + profile} title="Profile Image" alt="" />
                            }
                        </div>

                        <div className='profile_detail'>
                            <h2>{name}</h2>
                            <div className='star'>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <i class="bi bi-star-fill"></i>
                                <span>(38)</span>
                            </div>


                            <h3>{category}</h3>

                            <div className='just_row'>
                                <i class="bi bi-telephone"></i>
                                <h4>{mobile_number}</h4>
                            </div>

                            <div className='just_row_2'>
                                <i class="bi bi-envelope-at"></i>
                                <h4>{email}</h4>
                            </div>

                            <div className='just_row_2'>
                                <i class="bi bi-currency">₦</i>
                                <h4>{fee}</h4>
                            </div>
                        </div>
                    </div>

                    <div className='chanebtn_card'>
                        <div className={text1 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText1}>
                            <a href='#'>About Me</a>
                        </div>

                        <div className={text2 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText2}>
                            <a href='#'>Reviews</a>
                        </div>

                        <div className={text3 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText3}>
                            <a href='#'>Approved</a>
                        </div>

                        <div className={text4 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText4}>
                            <a href='#'>Edit</a>
                        </div>

                        <div className={text5 == false ? "about_me_btn_inactive" : "about_me_btn_active"} onClick={openText5}>
                            <a href='#'>Delete</a>
                        </div>
                    </div>


                    {text1 == true ?
                        <div className='About_in_btn'>
                            <h2>ABOUT</h2>
                            <hr></hr>

                            <div className='numer_licen'>
                                <h3>License Number</h3>
                                <h4>{license_number}</h4>
                                <hr></hr>
                            </div>

                            <div className='img_licen'>
                                <h3>License Image</h3>
                                {license_image == null ?
                                    <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                    :
                                    <img src={Image_connection + license_image} title="license Image" alt="" />
                                }
                            </div>
                        </div>
                        :
                        <div></div>
                    }


                    {text2 == true ?
                        <div className='Review_Card'>
                            <h2>Recent Review</h2>

                            {reviews == "" ?
                                <div className='Noreview'>
                                    <h4>No Review Available</h4>
                                </div>
                                :
                                <>
                                    {reviews.map((row) => (
                                        <div className='review_all'>
                                            {user_profile == null ?
                                                <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                                :
                                                <img src={Image_connection + user_profile} title="license Image" alt="" />
                                            }

                                            <div className='review_details'>
                                                <h3>{row.user_name}</h3>

                                                <p>{row.comment}</p>

                                                <div className='bottom_deatil_review'>
                                                    <div style={{ display: 'flex' }}>
                                                        <h3>({row.rating})</h3>
                                                        <ReactStars
                                                            count={5}
                                                            value={row.rating}
                                                            size={24}
                                                            edit={false}
                                                            activeColor="#ffd700"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </>
                            }

                        </div>
                        :
                        <div></div>
                    }



                    {text3 == true ?
                        <div className='Approv_Diss_Card'>
                            <h2>For Approval</h2>
                            <hr></hr>

                            <span className='two_buttun'>
                                {status == 'approved' ?
                                    <button onClick={() => { setModal_disapproved(true) }}>Disapproved</button>
                                    :
                                    <button onClick={() => { setModalapproved(true) }}>Approved</button>
                                }
                            </span>
                        </div>
                        :
                        <div></div>
                    }


                    {text4 == true ?
                        <form className='Update_seting_form'>
                            <h2>SETTING</h2>
                            <hr></hr>

                            <div class="updat_rst_name">
                                <div className='updt_width_both'>
                                    <div className='update_width_name'>
                                        <div className='updatsubject_name'>
                                            <label>Name</label>
                                        </div>
                                        <input type="text" class="" id="name" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>

                                    <div className='update_width_name'>
                                        <div className='updatsubject_name'>
                                            <label>Email</label>
                                        </div>
                                        <input type="email" class="" id="email" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                            </div>



                            <div class="updat_rst_name">
                                <div className='updt_width_both'>
                                    <div className='update_width_name'>
                                        <div className='updatsubject_name'>
                                            <label>Mobile Number</label>
                                        </div>
                                        <input type="text" class="" id="email" placeholder="Mobile Number" value={mobile_number} onChange={(e) => setMobile_number(e.target.value)} />
                                    </div>

                                    <div className='update_width_name'>
                                        <div className='updatsubject_name'>
                                            <label>Fee</label>
                                        </div>
                                        <input type="number" class="" id="email" placeholder="Your Fee" value={fee} onChange={(e) => setFee(e.target.value)} />
                                    </div>
                                </div>
                            </div>


                            <div class="updt_seond_subject">
                                <div className='updt_width_suject'>
                                    <div className='updatsubject_name'>
                                        <label>Address</label>
                                    </div>
                                    <input type="text" class="subject" id="subject" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                                </div>
                            </div>


                            <div class="updt_seond_subject">
                                <div className='updt_width_suject'>
                                    <div className='updatsubject_name'>
                                        <label>State</label>
                                    </div>
                                    <input type="text" class="subject" id="subject" placeholder="State" value={user_state} onChange={(e) => setUser_state(e.target.value)} />
                                </div>
                            </div>

                            <div class="updat_rst_name">
                                <div className='updt_width_both'>

                                    <div className='update_width_name'>
                                        <div className='updatsubject_name'>
                                            <label>Choose Category</label>
                                        </div>
                                        <select id="name" value={category} onChange={(e) => setCategory(e.target.value)}>
                                            <option >{category}</option>
                                            <option value="Dentists">Dentists</option>
                                            <option value="Cardialogy">Cardialogy</option>
                                            <option value="General Clinic">General Clinic</option>
                                            <option value="Pediatric">Pediatric</option>
                                        </select>
                                    </div>

                                    <div className='update_width_name'>
                                        <div className='updatsubject_name'>
                                            <label>Consult Now</label>
                                        </div>
                                        <select id="name" value={consult_now} onChange={(e) => setConsult_now(e.target.value)}>
                                            <option>{consult_now}</option>
                                            <option value="On">On</option>
                                            <option value="Off">Off</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <div class="updt_buttun_center">
                                <button class="submit" id='reset' type="reset">Reset</button>
                                {patLoading ?
                                    <button className='submit' id='delete_loader' href="">
                                        <PulseLoader
                                            color={"white"}
                                            loading={patLoading}
                                            //  size={5}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </button>
                                    :
                                    <button onClick={Update_doctor} class="submit" id='submite'>Submit</button>
                                }
                            </div>
                        </form>
                        :
                        <div></div>
                    }


                    {text5 == true ?
                        <div className='Delete_Card'>
                            <h2>Delete Account</h2>
                            <hr></hr>

                            <h4>Are you sure, You want to delete this provider?</h4>

                            <span className='delete_buttun'>
                                <button onClick={() => { setModalDelete(true) }}>Delete</button>
                            </span>
                        </div>
                        :
                        <div></div>
                    }
                </div>

                <div className='second_column'>
                    <div className='About_card'>
                        <div className='about_head'>
                            <h2>About Me</h2>
                        </div>

                        <hr></hr>

                        <div className='about_data'>
                            <p>{about}</p>
                            <hr></hr>
                            <div className='about_dat_row'>
                                <h3>Gender</h3>
                                <h4>{gender}</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Experience</h3>
                                <h4>{experience} years</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Degree</h3>
                                <h4>{degree}</h4>
                            </div>

                            <hr></hr>

                            <div className='about_dat_row'>
                                <h3>Primary Category</h3>
                                <h4>{primary_categories}</h4>
                            </div>

                            <hr></hr>

                            <div className='about_last_row'>
                                <div className='about_last_section'>
                                    <h3>37</h3>
                                    <h4>PATIENT</h4>
                                </div>

                                <div className='about_last_section'>
                                    <h3>61</h3>
                                    <h4>REVIEW</h4>
                                </div>

                                <div className='about_last_section'>
                                    <h3>{fee}</h3>
                                    <h4>FEE</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='Address_Card'>
                        <div className='address_head'>
                            <h2>Address</h2>
                        </div>

                        <hr></hr>

                        <div className='address_paragraph'>
                            <p>{address}</p>
                        </div>
                    </div>
                </div>

            </div>


            <Modal size='lg' isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
                <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
                    DELETE PATIENT
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to delete this patient?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Delete_patient}>Delete</button>
                        }
                    </div>
                </ModalBody>
            </Modal>



            <Modal size='lg' isOpen={modalapproved} toggle={() => setModalapproved(!modalapproved)}>
                <ModalHeader toggle={() => setModalapproved(!modalapproved)}>
                    APPROVAL
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to approved this provider?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Approved_doctor}>Approved</button>
                        }
                    </div>
                </ModalBody>
            </Modal>



            <Modal size='lg' isOpen={modal_disapproved} toggle={() => setModal_disapproved(!modal_disapproved)}>
                <ModalHeader toggle={() => setModal_disapproved(!modal_disapproved)}>
                    APPROVAL
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to disapproved this provider?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Dis_Approved_doctor}>Disapproved</button>
                        }
                    </div>
                </ModalBody>
            </Modal>


        </div>
    );
}