import React, { useState, useEffect, createContext } from 'react'
import './App.css';

import Drawer from './MyComponent/Drawer/Drawer';
import Sidenav from './MyComponent/Sidenav';
import Navbar from './MyComponent/Navbar/Navbar';
import Sidebar from './MyComponent/sidebar/Sidebar';
import Topbar from './MyComponent/topbar/Topbar';

import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Privacy_Policy from './pages/Privacy_Policy/Privacy_Policy.js';
import Appointment from './pages/Appointment/Appointment';
import Primary_categories from './pages/Primary_categories/Primary_categories';
import Secondary_categories from './pages/Secondary_categories/Secondary_categories';
import Add_doctor from './pages/Add_doctor/Add_doctor';
import Add_patient from './pages/Add_patient/Add_patient';
import Patient_list from './pages/Patient_list/Patient_list';
import Doctor_list from './pages/Doctor_list/Doctor_list';
import Doctor_Details from './pages/Doctor_Details/Doctor_Details';
import Refund from './pages/Refund/Refund';
import Pending_doctor from './pages/Pending_doctor/Pending_doctor';
import Transactions from './pages/Transactions/Transactions';
import Pending_payout from './pages/Pending_payout/Pending_payout';
import Percentage from './pages/Percentage/Percentage';
import Add_Admin from './pages/Add_Admin/Add_Admin';
import Admin_List from './pages/Admin_List/Admin_List';
import Review_Manage from './pages/Review_Manage/Review_Manage';

import Privatecom from './pages/Privatecom'


import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"

export const GlobalInfo = createContext()

function App() {

   const [color, setColor] = useState('green');
   const [date, setDate] = useState('green');
   const getdate = (item) => {
      console.log(item)
      setDate(item)
   }

   const auth = localStorage.getItem("user");

   useEffect(() => {
      console.log("auth", auth)
      if (auth) {
         const parsed = JSON.parse(auth);
         const side_tab_1 = parsed[0].side_tab;

         if (side_tab_1) {

            const side_tab_2 = JSON.parse(side_tab_1);
            const side_tab = side_tab_2.join(","); //"red,blue,green"

            console.log("side_tab_1 => ", side_tab_1)
            console.log("side_tab_2 => ", side_tab_2)
            console.log("side_tab => ", side_tab)
         }

      }

   }, [])

   return (

      <GlobalInfo.Provider value={{ appColor: color, getdate: getdate, date: date }}>
         <BrowserRouter>
            {!auth ? (
               <Routes>
                  <Route path='/login' element={<Login />}></Route>
                  <Route path='/*' element={<Login />}></Route>
               </Routes>
            ) : (
               <div className='app'>
                  {/* {date} */}
                  <Sidebar />

                  <div className='topbar_page'>
                     <Topbar />


                     <Routes>
                        <Route element={<Privatecom />} >


                           <Route path='/' element={<Home />}></Route>
                           <Route path='/Add_Admin' element={<Add_Admin />}></Route>
                           <Route path='/Admin_List' element={<Admin_List />}></Route>

                           <Route path='/Appointment' element={<Appointment />}></Route>
                           <Route path='/Primary_categories' element={<Primary_categories />}></Route>
                           <Route path='/Secondary_categories' element={<Secondary_categories />}></Route>
                           <Route path='/Add_doctor' element={<Add_doctor />}></Route>
                           <Route path='/Add_patient' element={<Add_patient />}></Route>
                           <Route path='/Patient_list' element={<Patient_list />}></Route>
                           <Route path='/Doctor_list' element={<Doctor_list />}></Route>
                           <Route path='/Doctor_Details/:id' element={<Doctor_Details />}></Route>
                           <Route path='/refund' element={<Refund />}></Route>
                           <Route path='/Pending_doctor' element={<Pending_doctor />}></Route>
                           <Route path='/Transactions' element={<Transactions />}></Route>
                           <Route path='/Pending_payout' element={<Pending_payout />}></Route>
                           <Route path='/Percentage' element={<Percentage />}></Route>
                           <Route path='/Review_Manage' element={<Review_Manage />}></Route>
                           <Route path='/Privacy_Policy' element={<Privacy_Policy />}></Route>
                           <Route path='/*' element={<Navigate to={'/'} />}></Route>

                        </Route>

                     </Routes>
                  </div>
               </div>
            )}
         </BrowserRouter>
      </GlobalInfo.Provider>

   );
}

export default App;
