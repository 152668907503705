import React, { useState } from 'react';
import { Button } from './Dropdown/Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import './Dropdown/Dropdown.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdowndoc, setDropdowndoc] = useState(false);
  const [dropdownpat, setdropdownpat] = useState(false);
  const [dropdownwallet, setdropdownwallet] = useState(false);
  
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnterdoc = () => {
    if (window.innerWidth < 960) {
      setDropdowndoc(false);
    } else {
      setDropdowndoc(true);
    }
  };

  const onMouseLeavedoc = () => {
    if (window.innerWidth < 960) {
      setDropdowndoc(false);
    } else {
      setDropdowndoc(false);
    }
  };


  const onMouseEnterpat = () => {
    if (window.innerWidth < 960) {
      setdropdownpat(false);
    } else {
      setdropdownpat(true);
    }
  };

  const onMouseLeavepat = () => {
    if (window.innerWidth < 960) {
      setdropdownpat(false);
    } else {
      setdropdownpat(false);
    }
  };


  const onMouseEnterwallet = () => {
    if (window.innerWidth < 960) {
      setdropdownwallet(false);
    } else {
      setdropdownwallet(true);
    }
  };

  const onMouseLeavewallet = () => {
    if (window.innerWidth < 960) {
      setdropdownwallet(false);
    } else {
      setdropdownwallet(false);
    }
  };


    const pat = [
      {
        title: 'Patient List',
        path: '/Patient_list',
        cName: 'dropdown-link'
      },
      {
        title: 'Add Patient',
        path: '/add_patient',
        cName: 'dropdown-link'
      }
    ];
  
    const doc = [
       {
         title: 'Doctor List',
         path: '/Doctor_list',
         cName: 'dropdown-link'
       },
       {
         title: 'Add Doctor',
         path: '/add_doctor',
         cName: 'dropdown-link'
       },
       {
        title: 'Pending Doctor',
        path: '/pending_doctor',
        cName: 'dropdown-link'
      },
    ];


    const wallet = [
      {
        title: 'Pending Payout',
        path: '/pending_payout',
        cName: 'dropdown-link'
       },
      {
        title: 'Refund',
        path: '/refund',
        cName: 'dropdown-link'
      },
      {
        title: 'Transactions',
        path: '/transactions',
        cName: 'dropdown-link'
      },
   ];

  return (
    <>
      <nav className='navbar'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
              Mass Clinicas 
              {/* <img src="https://m.media-amazon.com/images/I/31JaiPXYI8L._AC_UY327_FMwebp_QL65_.jpg" alt="" className="fab fa-firstdraft" /> */}
          </Link>
    
          <div className='menu-icon' onClick={handleClick}>
             <i className={'fas fa-bars'} />
          </div>
   
          <ul className={'nav-menu'}>
             <li className='nav-item'>
                <Link to='/' className='nav-links' onClick={closeMobileMenu}>Dashboard</Link>
             </li>

             <li className='nav-item'  onMouseEnter={onMouseEnterpat} onMouseLeave={onMouseLeavepat}>
                <Link className='nav-links' onClick={closeMobileMenu}>
                    Patient <i className='fas fa-caret-down' />
                </Link>

                {dropdownpat
                 &&
                 <ul onClick={handleClick} className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
                   {pat.map((item, index) => {
                     return (
                       <li key={index}>
                          <Link className={item.cName} to={item.path} onClick={() => setClick(false)}>
                             {item.title}
                          </Link>
                       </li>
                     );
                   })}
                 </ul>
                }
             </li>
         
             <li className='nav-item' onMouseEnter={onMouseEnterdoc} onMouseLeave={onMouseLeavedoc}>
                <Link  className='nav-links' onClick={closeMobileMenu}>
                    Doctor <i className='fas fa-caret-down' />
                </Link>
              
                {dropdowndoc
                 &&
                 <ul onClick={handleClick} className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
                   {doc.map((item, index) => {
                     return (
                       <li key={index}>
                          <Link className={item.cName} to={item.path} onClick={() => setClick(false)}>
                             {item.title}
                          </Link>
                       </li>
                     );
                   })}
                 </ul>
                }
             </li>
      
             <li className='nav-item'>
                <Link to='/Appointment' className='nav-links' onClick={closeMobileMenu}>Appointment</Link>
             </li>

             <li className='nav-item'  onMouseEnter={onMouseEnterwallet} onMouseLeave={onMouseLeavewallet}>
                <Link className='nav-links' onClick={closeMobileMenu}>
                    Wallet <i className='fas fa-caret-down' />
                </Link>

                {dropdownwallet
                 &&
                 <ul onClick={handleClick} className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
                   {wallet.map((item, index) => {
                     return (
                       <li key={index}>
                          <Link className={item.cName} to={item.path} onClick={() => setClick(false)}>
                             {item.title}
                          </Link>
                       </li>
                     );
                   })}
                 </ul>
                }
             </li>
          
             {/* <li>
                <Link to='/sign-up'className='nav-links-mobile'onClick={closeMobileMenu}>Sign Up</Link>
             </li> */}
          </ul>
      
          <Button />
      </nav>
    </>
  );
}

export default Navbar;
