import React, {useState, useEffect } from 'react'
import './Patient_list.css';
import { Modal,ModalHeader,ModalBody,Row } from 'reactstrap';
import { Link, parsePath,useParams } from "react-router-dom"
import Pagination from './Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";

export default function Patient_list() {

  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [products, setProducts]= useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentPosts = products.slice(firstPostIndex, lastPostIndex)
  
  const [user_id, setUser_id] = React.useState("");
  const [name, setName] = React.useState("");
  const [mobile_number, setMobile_number] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [patLoading, setPatLoading] = useState(false);

  const params = useParams();

    useEffect(()=>{
      getProducts()
    },[])
    const getProducts = async()=>{
        let  result =await fetch(Connection + "get_user",{
           
        })
        result= await result.json()
         setProducts(result)
         console.warn(result)
    }


    const Update_patient =async(event)=>{
      event.preventDefault();
      //  if(!name || !email || !mobile_number || !address){
      //      setError(true)
      //      alert('Information is not complete');
      //      return false
      //  }     
       setPatLoading(true);

      //  setTimeout(() => {
      //    setPatLoading(false);
      //    setModalEdit(false)
      //  }, 1000);
   
    
       console.warn(name,email,mobile_number,address,password)
   
       let  result =await fetch(Connection + `update_patient/${user_id}`,{
           method:'post',
           body: JSON.stringify({name,email,mobile_number,address,password}),
           headers:{
               "Content-Type":'application/json'
           }
       })
       result=await result.json()
       console.log("result123",result)
       if(result){
          setPatLoading(false);
          setModalEdit(false)
          getProducts()
       }
 
      
         //   navigate('/')
    
    }


    const open_edit_modal = (id,name,email,mobile_number,address,password) => {
       setModalEdit(true)

       setUser_id(id)
       setName(name)
       setEmail(email)
       setMobile_number(mobile_number)
       setAddress(address)
       setPassword(password)
    }

    const open_delete_modal = (id) => {
       setModalDelete(true)

       setUser_id(id)
    }

    const Search_Doctor =async(event)=>{
      event.preventDefault();

      const name = event.target.value
      console.log("search",name)
   
       let  result =await fetch(Connection + "search_patient",{
           method:'post',
           body: JSON.stringify({name}), 
           headers:{
               "Content-Type":'application/json'
           }
       })
       result=await result.json()
       console.log("result123",result)
       setProducts(result)
    }



    const Delete_patient =async(event)=>{
      event.preventDefault();

       setPatLoading(true);

       let  result =await fetch(Connection + `delete_patient/${user_id}`)
       result=await result.json()
       console.log("result123",result)

       if(result){
          setPatLoading(false);
          setModalDelete(false)
          getProducts()
       }
    }


    return (
        <div className='table_view'>
           
            <div className='responsive_card'>
               <div className='head_card'>
                   <h2>PATIENT</h2>
                   <div className='search_patient'>
                      <h6>Search</h6>
                      <input type="search" className="search" id="Search" placeholder="Search"   onChange={Search_Doctor}/>
                   </div>
               </div>
              <hr></hr>
 
              <div className='table_responsive'>
                <table className='table_tag'>
                           <tr>
                               <th>ID</th>
                               <th>Image</th>
                               <th>Name</th>
                               <th>Email</th>
                               <th>Phone</th>
                               <th>Action</th>
                           </tr>


                      {currentPosts.map((row) => (
                           <tr>
                               <td>{row.id}</td>
                               <td>
                                 {row.profile == null ?
                                      <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt=""/>
                                 :
                                      <img src={Image_connection + row.profile} title="Profile Image" alt=""/>
                                 }
                               </td>
                               <td>{row.name}</td>
                               <td>{row.email}</td>
                               <td>{row.mobile_number}</td>
                               <td>
                                  <span className='action_btn'>
                                       <button onClick={() => open_edit_modal(row.id,row.name,row.email,row.mobile_number,row.address,row.password)}>Edit</button>
                                       <button onClick={() => open_delete_modal(row.id)}>Remove</button>
                                  </span>
                               </td>
                           </tr>
                      ))}
                </table>
             </div>
            </div>

            <Pagination totalPosts={products.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>

            <Modal size='lg' isOpen={modalEdit} toggle={() => setModalEdit(!modalEdit)}>
               <ModalHeader toggle={() => setModalEdit(!modalEdit)}>
                   UPDATE PATIENT
               </ModalHeader>

               <ModalBody>

               <form className='Form_all_patient'>
     
               <div class="patient_first_name">
                  <div className='update_width_both_name_email'>
                     <div className='update_width_name'>
                        <div className='updt_subject_name'>
                           <label>Name</label>
                        </div> 
                        <input type="text" class="" id="name" placeholder="Your Name" value={name}  onChange={(e)=>setName(e.target.value)}/>
                     </div>

                     <div className='update_width_name'>
                        <div className='updt_subject_name'>
                           <label>Email</label>
                        </div>
                        <input type="email" class="" id="email" placeholder="Your Email" value={email}  onChange={(e)=>setEmail(e.target.value)}/>
                     </div>
                  </div>
               </div>

               <div class="update_second_subject">
                  <div className='updat_width_subject'>
                     <div className='updt_subject_name'>
                        <label>Address</label>
                     </div>
                     <input type="text" class="subject" id="subject" placeholder="Address" value={address}  onChange={(e)=>setAddress(e.target.value)}/>
                  </div>
               </div>

               <div class="patient_first_name">
                  <div className='update_width_both_name_email'>
                     <div className='update_width_name'>
                        <div className='updt_subject_name'>
                           <label>Mobile Number</label>
                        </div>
                        <input type="number" class="" id="email" placeholder="Mobile Number"  value={mobile_number}  onChange={(e)=>setMobile_number(e.target.value)}/>
                     </div>
                     
                     <div className='update_width_name'>
                        <div className='updt_subject_name'>
                           <label>Password</label>
                        </div>
                        <input type="text" class="" id="name" placeholder="Your Password" value={password}  onChange={(e)=>setPassword(e.target.value)}/>
                     </div>
                  </div>
               </div>

               <div class="update_buttun">
                  <button class="submit" id='reset' type="reset">Reset</button>
                  {patLoading ?
                      <button class="submit" id='loader' type="reset">
                          <PulseLoader
                               color={"white"}
                               loading={patLoading}
                              //  size={5}
                               aria-label="Loading Spinner"
                               data-testid="loader"
                          />
                     </button>
                   :
                     <button  onClick={Update_patient} class="submit" id='submite' type="submit">Update</button>
                  }
               </div>
            </form>
   
               </ModalBody>
            </Modal>

            <Modal size='lg' isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
               <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
                   DELETE PATIENT
               </ModalHeader>

               <ModalBody>
                  <div className='delete_modal'>
                      <h4>Are you sure you want to delete this patient?</h4>
                      {patLoading ?
                        <button id='delete_loader'>
                          <PulseLoader
                               color={"white"}
                               loading={patLoading}
                              //  size={5}
                               aria-label="Loading Spinner"
                               data-testid="loader"
                          />
                        </button>
                      :
                        <button onClick={Delete_patient}>Delete</button>
                      }
                  </div>
               </ModalBody>
            </Modal>
         </div>
    );
}