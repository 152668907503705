import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import './Pending_doctor.css';
import FadeLoader from "react-spinners/FadeLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";

export default function Doctor_list() {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getPending_doctor()
    }, [])
    const getPending_doctor = async () => {
        setIsLoading(true);
        let result = await fetch(Connection + "pending_doctor", {

        })
        result = await result.json()
        setProducts(result)
        setIsLoading(false);

        console.warn(result)
    }

    return (
        <React.Fragment>
            <div className='main_view'>
                <h2>PENDING PROVIDER</h2>

                {isLoading ? (
                    <div className='loader_doctor_list'>
                        <FadeLoader
                            color={"#29166f"}
                            loading={isLoading}
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p>Loading ...</p>
                    </div>
                )
                    :
                    (
                        <div className='all_kard'>
                            {products.map((row) => (
                                <div className='displayblock'>
                                    <div className='doctor_cad_2'>
                                        {row.profile == null ?
                                            <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt="" />
                                            :
                                            <img src={Image_connection + row.profile} title="Profile Image" alt="" />
                                        }

                                        <div className='list_in_cad_2'>
                                            <h6>{row.name}</h6>

                                            <h5>₦{row.fee}</h5>
                                            <h5>{row.email}</h5>
                                            <h4>{row.mobile_number}</h4>

                                        </div>
                                        <span className='book_appo'>
                                            <Link to={"/Doctor_Details/" + row.id}>Provider Profile</Link>
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

            </div>
        </React.Fragment>
    );
}