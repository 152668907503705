import React, { useState, useEffect, useMemo } from 'react'
import { Link, useNavigate } from "react-router-dom"
import './Add_doctor.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { Allcountry } from "../../Data/Allcountry";
import { Allstate } from "../../Data/State";

export default function Add_doctor() {

   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [mobile_number, setMobile_number] = useState("");
   const [experience, setExperience] = useState("");
   const [degree, setDegree] = useState("");
   const [address, setAddress] = useState("");
   const [country, setCountry] = useState("");
   const [user_state, setUser_state] = useState("");
   const [multicategory, setMulticategory] = useState("");
   const [primary_category, setPrimary_category] = useState("");
   const [fee, setFee] = useState("");
   const [about, setAbout] = useState("");
   const [consult_now, setConsult_now] = useState("");

   const [modalsuccess, setModalsuccess] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const [error, setError] = useState(false);

   const [categories, setCategories] = useState([]);
   const [primary_categories, setPrimary_categories] = useState([]);
   const [con, setCon] = useState([]);
   const [Lat, setLat] = useState("");
   const [Lng, setLng] = useState("");



   const navigate = useNavigate();


   useEffect(() => {
      get_primary_categories()
   }, [])


   //  Category
   const get_primary_categories = async () => {
      let result = await fetch(Connection + "primary_categories", {})
      result = await result.json()

      setPrimary_categories(result)
   }


   const get_categories = async (name) => {

      console.warn(name)
      let result = await fetch(Connection + "get_categories", {
         method: 'post',
         body: JSON.stringify({ name }),
         headers: {
            "Content-Type": 'application/json'
         }
      })

      result = await result.json()

      setCategories(result)
   }
   // 


   const onSelect = (selectedList, selectedItem) => {
      console.log("sds", selectedList)
      var names = selectedList.map(function (i) {
         return i.name;
      });
      setMulticategory(names)
   }

   const onRemove = (selectedList, removedItem) => {
      console.log("sds")

   }

   ///////////////////////////




   // Get Lat or Long
   const handleInputBlur = event => {
      event.preventDefault();
      const completedValue = event.target.value;
      handleFormSubmit(completedValue); // Call your function with the completed value
   };

   const handleFormSubmit = value => {
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(value)}&key=AIzaSyAr6YRTjfl2eKXIJZd97_uw9KpStXFRiCE`)
         .then(response => response.json())
         .then(data => {
            const { lat } = data.results[0].geometry.location;
            const { lng } = data.results[0].geometry.location;
            console.log("Getting Lat", lat)
            console.log("Getting Lng", lng)
            setLat(lat);
            setLng(lng);
         });
   };


   const Add_Doctor = async (event) => {
      event.preventDefault();
      if (!name || !email || !password || !mobile_number || !experience || !degree || !address || !country || !user_state || !multicategory || !primary_category || !fee || !about || !consult_now) {
         setError(true)
         alert('Information is not complete');
         return false
      }
      if (fee <= 1306) {
         setError(true)
         alert('Provider fee should be greater');
         return false
      }

      setIsLoading(true);
      const role = "doctor"
      const status = "approved"
      const disable = "false"

      var category = JSON.stringify(multicategory)

      console.warn(name, email, password, mobile_number, experience, degree, address, Lat, Lng, country, user_state, category, primary_category, fee, about, consult_now)

      let result = await fetch(Connection + "add_doctor", {
         method: 'post',
         body: JSON.stringify({ name, email, password, mobile_number, experience, degree, address, Lat, Lng, country, user_state, category, primary_category, fee, about, role, status, consult_now, disable }),
         headers: {
            "Content-Type": 'application/json'
         }
      })

      result = await result.json()
      console.log("result123", result)
      console.log("result.auth", result.auth)

      if (result) {
         setIsLoading(false);
         setModalsuccess(true)
      }
   }


   const Succeess = async (event) => {
      setModalsuccess(false)
      navigate('/Doctor_list')
   }


   const onsinglecategory = async (e) => {
      setPrimary_category(e.target.value)
      console.log("eeeee", e.target.value)

      get_categories(e.target.value)
   }

   const onsinglecountry = async (e) => {

      const searchIndex = Allstate.findIndex((country) => country.country_name == e.target.value);

      con.push(...Allstate[searchIndex].states);

      setCountry(e.target.value)
      console.log("eeeee", e.target.value)

   }

   return (
      <div className='firstinpu'>

         <form className='Form-all-view'>
            <h2>ADD PROVIDER</h2>
            <hr></hr>

            {/* <div className='profileImage'>
                   <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="" className="fab fa-firstdraft" />
                   <a href='' className='prfilebtn'>Add Profile</a>
               </div> */}

            <div className="first-name">
               <div className='width-both-name-email'>
                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Name</label>
                     </div>
                     <input type="text" className="" id="name" placeholder="Your Name" onChange={(e) => setName(e.target.value)} />
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Email</label>
                     </div>
                     <input type="email" className="" id="email" placeholder="Your Email" onChange={(e) => setEmail(e.target.value)} />
                  </div>
               </div>
            </div>


            <div className="first-name">
               <div className='width-both-name-email'>
                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Password</label>
                     </div>
                     <input type="text" className="" id="name" placeholder="Your Password" onChange={(e) => setPassword(e.target.value)} />
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Mobile Number</label>
                     </div>
                     <input type="text" className="" id="email" placeholder="Mobile Number" onChange={(e) => setMobile_number(e.target.value)} />
                  </div>
               </div>
            </div>


            <div className="first-name">
               <div className='width-both-name-email'>
                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Year of Experience</label>
                     </div>
                     <input type="text" className="" id="name" placeholder="Experience" onChange={(e) => setExperience(e.target.value)} />
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Education</label>
                     </div>
                     <input type="text" className="" id="email" placeholder="Education" onChange={(e) => setDegree(e.target.value)} />
                  </div>
               </div>
            </div>



            <div className="second-subject">
               <div className='width-subject'>
                  <div className='subject-name'>
                     <label>Address{con}</label>
                  </div>
                  <input type="text" className="subject" id="subject" placeholder="Address" onBlur={handleInputBlur} onChange={(e) => setAddress(e.target.value)} />
               </div>
            </div>

            <div className="second-subject">
               <div className='width-subject'>
                  <div className='subject-name'>
                     <label>Country</label>
                  </div>

                  <select id="subject" onChange={onsinglecountry}>
                     <option>Country</option>
                     {Allcountry.map((option, i) =>
                        <option value={option.country_name}>{option.country_name}</option>)}
                  </select>
                  {/* <input type="text" className="subject" id="subject" placeholder="Country" onChange={(e)=>setCountry(e.target.value)}/> */}
               </div>
            </div>




            <div className="first-name">
               <div className='width-both-name-email'>
                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>State</label>
                     </div>

                     <select id="name" onChange={(e) => setUser_state(e.target.value)}>
                        <option>State</option>
                        {con.map((option, i) =>
                           <option value={option.state_name}>{option.state_name}</option>)}
                     </select>

                     {/* <input type="text" className="" id="name" placeholder="State" onChange={(e)=>setUser_state(e.target.value)}/> */}
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Fee</label>
                     </div>
                     <input type="number" className="" id="email" placeholder="Fee" onChange={(e) => setFee(e.target.value)} />
                  </div>
               </div>
            </div>


            <div className="first-name">
               <div className='width-both-name-email'>

                  <div className='update_width_name'>
                     <div className='updatsubject_name'>
                        <label>Primary Category</label>
                     </div>

                     <select id="name" onChange={onsinglecategory}>
                        <option>Primary Category</option>
                        {primary_categories.map((option, i) =>
                           <option value={option.name}>{option.name}</option>)}
                     </select>
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Choose Category</label>
                     </div>

                     <Multiselect
                        placeholder="Sub Category"
                        emptyRecordMsg="Select Primary Category"
                        options={categories} // Options to display in the dropdown
                        //  selectedValues={"Select category"} // Preselected value to persist in dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                     />
                  </div>

               </div>
            </div>


            <div className="first-name">
               <div className='width-both-name-email'>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Consult Now</label>
                     </div>
                     <select id="name" onChange={(e) => setConsult_now(e.target.value)}>
                        <option>Choose</option>
                        <option value="On">On</option>
                        <option value="Off">Off</option>
                     </select>
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>About</label>
                     </div>
                     <input type="text" className="" id="email" placeholder="About" onChange={(e) => setAbout(e.target.value)} />
                  </div>
               </div>
            </div>

            {/* <div className="second-subject">
                  <div className='width-subject'>
                     <div className='subject-name'>
                        <label>Message</label>
                     </div>
                     <textarea className="message" placeholder="Leave a message here" id="message" ></textarea>
                  </div>
               </div> */}

            <div className="buttun-center">
               <button className="submit" id='reset' type="reset">Reset</button>
               {isLoading ?
                  <button class="submit" id='loader'>
                     <PulseLoader
                        color={"white"}
                        loading={isLoading}
                        //  size={5}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                     />
                  </button>
                  :
                  <button onClick={Add_Doctor} className="submit" id='submite'>Submit</button>
               }
            </div>
         </form>

         <Modal size='lg' isOpen={modalsuccess} toggle={() => setModalsuccess(!modalsuccess)}>
            <ModalHeader toggle={() => setModalsuccess(!modalsuccess)}>
               Successfully
            </ModalHeader>

            <ModalBody>
               <div className='succeess_modal'>
                  <h4>You have successfully Add Provider</h4>
                  <button onClick={Succeess}>Done</button>
               </div>
            </ModalBody>
         </Modal>
      </div>
   );
}